import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, notification } from "antd";
import "./App.css";
import axios from "axios";
import LoginHeader from './componant/LoginHeader';
import { ReactComponent as LoginImg } from "../src/images/login-img.svg";
import {
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminLogin = () => {
  const [mobile, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [mobile2, setEmail] = useState('');
  const navigate = useNavigate();
  const [showOtpFieldforlogin, setShowOtpFieldforlogin] = useState(true);
  const [otp, setOtp] = useState("");
  const [responseMessageLogiin, setResponseMessageLogin] = useState("");
  const [showRegisterConfirmPassword, setShowRegisterConfirmPassword] = useState(false);
  const toggleRegisterConfirmPasswordVisibility = () => {
    setShowRegisterConfirmPassword(!showRegisterConfirmPassword);
  };
  const token = "";
  // const [logoUrl, setLogoUrl] = useState(''); 
  const [clientDetails, setClientDetails] = useState(null);
  const [otpResponseMessage, setOtpResponseMessage] = useState('');
  const [submiOtpResponse, setSubmiOtpResponse] = useState('');
  const handleLogin = async () => {
    try {
      const response = await fetch('https://api.starmallonline.com/api/v1/login/authenticateadmin', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mobile, password }),
      });

      if (response.ok) {
        const data = await response.json();

        // Fetch client details
        const getClientDetailsResponse = await fetch(
          "https://api.starmallonline.com/api/v1/login/getuserdetails",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          }
        );

        if (getClientDetailsResponse.ok) {
          const clientData = await getClientDetailsResponse.json();

          // Store client data in localStorage
          localStorage.setItem('id', clientData.id);
          localStorage.setItem('firstname', clientData.firstname);
          localStorage.setItem('email', clientData.email);
          localStorage.setItem('role', clientData.role);

          const currentDate = new Date();
          const planExpiryDate = clientData.planexpiry ? new Date(clientData.planexpiry) : null;

          // Handle ADMIN user
          if (clientData.role === "ADMIN") {
            localStorage.setItem("token", data.token);
            navigate('../main-category');
          }
          // Handle SELLER user
          else if (clientData.role === "SELLER") {
            sessionStorage.setItem("tokenforseller", data.token);

            // Check registration stages
            if (clientData.registration === "stage1") {
              navigate('../Stage1');
            } else if (clientData.registration === "stage2") {
              navigate('../Stage2');
            } else if (clientData.registration === "stage3") {
              navigate('../Stage3');
            }
            // Handle expired plan case
            else if (planExpiryDate && planExpiryDate < currentDate) {
              console.log('Navigating to /Repayment');
              navigate('/Repayment');
            }
            // Completed registration
            else if (clientData.registration === "completed") {
              navigate('../SellerDashBoard');
            }
          }
          console.log("Client Data:", clientData);

        } else {
          console.error("Failed to get client details");
        }
      } else {
        // Handle login failure cases
        const responseData = await response.text();
        if (responseData.includes("Password incorrect") || responseData.includes("User not Registered with us")) {
          setResponseMessageLogin(responseData); // Display error in the UI
        } else {
          console.error('Admin Login failed:', responseData);
        }
      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  // useEffect(() => {
  //   const fetchLogo = async () => {
  //     try {
  //       const response = await fetch('https://api.starmallonline.com/api/v1/logo/get', {
  //         method: 'PUT',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       });
  //       if (response.ok) {
  //         const data = await response.text();
  //         setLogoUrl(data);
  //         sessionStorage.setItem("logoUrl", data);
  //       } else {
  //         console.error('Failed to fetch logo');
  //       }
  //     } catch (error) {
  //       console.error('Error fetching logo:', error);
  //     }
  //   };

  //   const fetchUserDetails = async () => {
  //     try {
  //       const response = await fetch("https://api.starmallonline.com/api/v1/login/getuserdetails", {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });

  //       if (response.ok) {
  //         const userData = await response.json();
  //         setClientDetails(userData); // Set the user details in state
  //       } else {
  //         console.error("Failed to get user details:", response.status);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching user details:", error);
  //     }
  //   };

  //   fetchLogo();
  //   fetchUserDetails(); // Call the function to fetch user details

  // }, []); // Add any dependencies if needed

  const handleSellerRegistration = () => {
    navigate('/Seller');  // Update '/seller' with the actual path to your Seller page
  };
  const sendOtp = async () => {
    try {
      // Create a request payload with the mobile number
      const requestData = {
        mobile: mobile2, // Use the state variable 'mobile' for the mobile number
      };

      // Make an HTTP POST request to the mobileotpclient API
      const response = await axios.put(
        'https://api.starmallonline.com/api/v1/client/mobileotpclient',
        requestData
      );

      if (response.status === 200) {
        // Successfully sent OTP
        setOtpResponseMessage('OTP sent successfully. Check your mobile for the OTP.');
        setShowOtpFieldforlogin(false);
      } else {
        // Handle other response statuses if needed
        setOtpResponseMessage('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      // Handle errors, e.g., network issues
      console.error('Error sending OTP:', error);
      setOtpResponseMessage('An error occurred while sending OTP. Please try again later.');
    }

  };
  const submitOtp = async () => {
    try {
      const response = await axios.put("https://api.starmallonline.com/api/v1/login/sellerotp", {
        otp,
        mobile: mobile2,
      });

      if (response.status >= 200 && response.status < 300) {
        const data = response.data;

        // Now, instead of response.ok, check the status of the second request
        const getClientDetailsResponse = await fetch("https://api.starmallonline.com/api/v1/login/getuserdetails", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });

        if (getClientDetailsResponse.status >= 200 && getClientDetailsResponse.status < 300) {
          const clientData = await getClientDetailsResponse.json();
          setClientDetails(clientData);
          const planExpiryDate = new Date(clientData.planexpiry);
          const currentDate = new Date();
          if (clientData.role === "ADMIN") {
            localStorage.setItem("token", data.token);
            navigate('../main-category');
          } else if (clientData.role === "SELLER") {
            sessionStorage.setItem("tokenforseller", data.token);
            if (clientData.registration === "stage1") {
              navigate('../Stage1');
            }
            else if (clientData.registration === "stage2") {
              navigate('../Stage2');
            }
            else if (clientData.registration === "stage3") {
              navigate('../Stage3');
            } else if (planExpiryDate < currentDate) {
              navigate('/Repayment');
            } else if (clientData.registration === "completed") {
              navigate('../ProductAddforVendor');
            }

          }
        } else {
          console.error("Failed to get client details", getClientDetailsResponse.status);
        }
      } else {
        console.error("Admin Login failed", response.status);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  return (
    <div>
      <LoginHeader />
      <div className="login-outbox d-flex align-items-center  justify-content-center">
        <div className="login-wrappere login">

          <h2>Login</h2>
          <div className=" d-flex justify-content-center">

            <div className="left"> <LoginImg alt="Logo" width='100%' /> </div>
            <div className="right">


              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
              >
                <Form.Item
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Username!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                    value={mobile}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: !showRegisterConfirmPassword,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type={showRegisterConfirmPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    suffix={
                      <button
                        type="button"
                        onClick={toggleRegisterConfirmPasswordVisibility}
                        className="password-toggle-button"
                      >
                        {showRegisterConfirmPassword ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}
                      </button>
                    }
                  />
                </Form.Item>

                <Form.Item className="d-flex justify-content-end">
                  <Link to="/ForgotPassword" className="login-form-forgot">
                    Forgot password
                  </Link>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="login-form-button"
                    style={{ width: "100%" }}
                    onClick={handleLogin}
                  >
                    Log in
                  </Button>
                  {responseMessageLogiin && <div className="error-message" style={{ color: "red" }}>{responseMessageLogiin}</div>}
                </Form.Item>

              </Form>
              <Form.Item>
                <h5 className="login-with-otp">
                  Or Login with OTP
                </h5>
              </Form.Item>
              <Form.Item
                name="Mobile"
              >
                <Input
                  placeholder="Mobile no./Email"
                  value={mobile2}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit3"
                  className="button3"
                  style={{ width: "100%", display: showOtpFieldforlogin ? 'block' : 'none' }}
                  onClick={sendOtp}
                >
                  Verify
                </Button>
                {otpResponseMessage && <div className="response-message">{otpResponseMessage}</div>}
              </Form.Item>
              {/* <Form.Item>
        <Button
          htmlType="submit4"
          className="button4"
          style={{ width: "50%" , display: !showOtpFieldforlogin ? 'block' : 'none' }}
          onClick={sendOtp}
        >
          Resend OTP
        </Button>
      </Form.Item> */}
              <div style={{ display: !showOtpFieldforlogin ? 'block' : 'none' }}>
                <Form.Item
                  name="OTP"
                >
                  <Input
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit2"
                    className="login-form-button2"
                    style={{ width: "100%" }}
                    onClick={submitOtp}
                  >
                    Submit Otp
                  </Button>
                  {submiOtpResponse && <div className="response-message">{submiOtpResponse}</div>}
                </Form.Item>
              </div>



            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
