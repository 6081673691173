import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import './style.scss';
import './riot_admin.scss';
import './materialdesignicons.scss';
import Logo from "./images/logo-web.jpg";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import { faL } from '@fortawesome/free-solid-svg-icons';

const menuItems = [
  // { text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
  // Add more menu items as needed  
];
const SideBar = () => {
  const navigate = useNavigate();
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        setIsTokenExpired(true);
        localStorage.removeItem('token');
        navigate("/AdminLogin");
      } else {
        setIsTokenExpired(false);
      }
    }
  }, []);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState({
    vendors: false,
    user: false,
    categories: false,
    products: false,
    orders: false,
    authentication: false,
    icons: false,
    otherpages: false,
    banner: false,
    offer: false,
    cinfo: false,
    cdetails: false,
    cminfo: false,
    coupon: false,
    pincode: false,
    deliverycharges: false,
    showpincode: false,
    vendor: false,
    deliverman: false,
    milkproduct: false,
    assigndeliverman: false,
  });
  const toggleSubMenu = (menuName) => {
    setIsSubMenuOpen((prevState) => ({
      ...prevState,
      [menuName]: !prevState[menuName],
    }));
  };
  function handleLogout() {
    // Navigate to the login page
    window.location.href = '/AdminLogin';
  }

  return (
    <div className="ec-left-sidebar ec-bg-sidebar">
      <div id="sidebar" className="sidebar ec-sidebar-footer">

        <div className="ec-brand">
          <a href="index" title="Ekka">
            <div className='navbar-logo '>
              <img
                id="sidebar-toggler"
                className="sidebar-toggle" src={Logo}
                style={{ width: "170px" }}
              ></img></div>
          </a>
        </div>
        <div className="ec-navigation" data-simplebar>
          <ul className="nav sidebar-inner" id="sidebar-menu">
            {menuItems.map((item, index) => (
              <li key={index}>
                <a className="sidenav-item-link" href={item.link}>
                  <i className={item.icon}></i>
                  <span className="nav-text">{item.text}</span>
                </a>
              </li>
            ))}
            <li className={`has-sub ${isSubMenuOpen.categories ? 'expand' : ''}`}>
              <a className="sidenav-item-link" href="#" onClick={() => toggleSubMenu('categories')}>
                <i className="mdi mdi-dns-outline"></i>
                <span className="nav-text">Categories</span>
                <b className="caret"></b>
              </a>
              <div className={`collapse ${isSubMenuOpen.categories ? 'show' : ''}`}>
                <ul className="sub-menu" id="categorys" data-parent="#sidebar-menu">
                  <li className="">
                    <a className="sidenav-item-link" href="main-category">
                      <span className="nav-text">Main Category</span>
                    </a>
                  </li>
                  <li className="">
                    <a className="sidenav-item-link" href="sub-category">
                      <span className="nav-text">Sub Category</span>
                    </a>
                  </li>
                  <li className="">
                    <a className="sidenav-item-link" href="subsub-category">
                      <span className="nav-text">SubSub Category</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className={`has-sub ${isSubMenuOpen.products ? 'expand' : ''}`}>
              <a className="sidenav-item-link" href="#" onClick={() => toggleSubMenu('products')}>
                <i className="mdi mdi-palette-advanced"></i>
                <span className="nav-text">Products</span>
                <b className="caret"> </b>
              </a>
              <div className={`collapse ${isSubMenuOpen.products ? 'show' : ''}`}>
                <ul className="sub-menu" id="vendors" data-parent="#sidebar-menu">
                  <li className="">
                    <a className="sidenav-item-link" href="product-add">
                      <span className="nav-text">Add Product</span>
                    </a>
                  </li>
                  <li className="">
                    <a className="sidenav-item-link" href="product-list">
                      <span className="nav-text">List Product</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className={`has-sub ${isSubMenuOpen.banner ? 'expand' : ''}`}>
              <a className="sidenav-item-link" href="#" onClick={() => toggleSubMenu('banner')}>
                <i className="mdi mdi-camera-image"></i>
                <span className="nav-text">Banner</span>
                <b className="caret"></b>
              </a>
              <div className={`collapse ${isSubMenuOpen.banner ? 'show' : ''}`}>
                <ul className="sub-menu" id="categorys" data-parent="#sidebar-menu">
                  <li className="">
                    <a className="sidenav-item-link" href="main-banner">
                      <span className="nav-text">Add Banner</span>
                    </a>
                  </li>
                  <li className="">
                    <a className="sidenav-item-link" href="main-offer">
                      <span className="nav-text">Add Offer</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            {/* <li className={`has-sub ${isSubMenuOpen.deliverman ? 'expand' : ''}`}>
              <a className="sidenav-item-link" href="#" onClick={() => toggleSubMenu('deliverman')}>
                <i className="mdi mdi-camera-image"></i>
                <span className="nav-text">Delivery Man</span>
                <b className="caret"></b>
              </a>
              <div className={`collapse ${isSubMenuOpen.deliverman ? 'show' : ''}`}>
                <ul className="sub-menu" id="categorys" data-parent="#sidebar-menu">
                  <li className="">
                    <a className="sidenav-item-link" href="deliverman-add">
                      <span className="nav-text">Add Delivery Man</span>
                    </a>
                  </li>
                  <li className="">
                    <a className="sidenav-item-link" href="deliverman-list">
                      <span className="nav-text">List Delivery Man</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className={`has-sub ${isSubMenuOpen.milkproduct ? 'expand' : ''}`}>
              <a className="sidenav-item-link" href="#" onClick={() => toggleSubMenu('milkproduct')}>
                <i className="mdi mdi-camera-image"></i>
                <span className="nav-text">Milk Product</span>
                <b className="caret"></b>
              </a>
              <div className={`collapse ${isSubMenuOpen.milkproduct ? 'show' : ''}`}>
                <ul className="sub-menu" id="categorys" data-parent="#sidebar-menu">
                  <li className="">
                    <a className="sidenav-item-link" href="milkproduct-add">
                      <span className="nav-text">Add Milk Product</span>
                    </a>
                  </li>
                  <li className="">
                    <a className="sidenav-item-link" href="milkproduct-list">
                      <span className="nav-text">List Milk Product</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className={`has-sub ${isSubMenuOpen.assigndeliverman ? 'expand' : ''}`}>
              <a className="sidenav-item-link" href="#" onClick={() => toggleSubMenu('assigndeliverman')}>
                <i className="mdi mdi-camera-image"></i>
                <span className="nav-text">Assign Delivery Man</span>
                <b className="caret"></b>
              </a>
              <div className={`collapse ${isSubMenuOpen.assigndeliverman ? 'show' : ''}`}>
                <ul className="sub-menu" id="categorys" data-parent="#sidebar-menu">
                  <li className="">
                    <a className="sidenav-item-link" href="assigndeliverman-add">
                      <span className="nav-text">Add Assign Delivery Man</span>
                    </a>
                  </li>
                  <li className="">
                    <a className="sidenav-item-link" href="assigndeliverman-list">
                      <span className="nav-text">List Assign Delivery Man</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li> */}
            <li className={`has-sub ${isSubMenuOpen.vendor ? 'expand' : ''}`}>
              <a href="main-Vendors" className="sidenav-item-link">
                <i className="mdi mdi-account-tie"></i>
                <span className="nav-text">Sellers</span>
              </a>
            </li>
            <li className={`has-sub ${isSubMenuOpen.vendor ? 'expand' : ''}`}>
              <a href="main-sellerPlan" className="sidenav-item-link">
                <i className="mdi mdi-account-tie"></i>
                <span className="nav-text">Seller Plan</span>
              </a>
            </li>
            <li className={`has-sub ${isSubMenuOpen.cinfo ? 'expand' : ''}`}>
              <a href="main-common" className="sidenav-item-link">
                <i className="mdi mdi-creative-commons"></i>
                <span className="nav-text">Common Info</span>
              </a>
            </li>
            <li className={`has-sub ${isSubMenuOpen.cinfo ? 'expand' : ''}`}>
              <a href="main-Icons" className="sidenav-item-link">
                <i className="mdi mdi-creative-commons"></i>
                <span className="nav-text">Social Media</span>
              </a>
            </li>
            <li className={`has-sub ${isSubMenuOpen.cdetails ? 'expand' : ''}`}>
              <a href="clientDetails" className="sidenav-item-link">
                <i className="mdi mdi-account-group"></i>
                <span className="nav-text">User</span>
              </a>
            </li>
            <li className={`has-sub ${isSubMenuOpen.orders ? 'expand' : ''}`}>
              <a className="sidenav-item-link" href="#" onClick={() => toggleSubMenu('orders')}>
                <i className="mdi mdi-account-group-outline"></i>
                <span className="nav-text">Orders</span> <b className="caret"></b>
              </a>
              <div className={`collapse ${isSubMenuOpen.orders ? 'show' : ''}`}>
                <ul className="sub-menu" id="vendors" data-parent="#sidebar-menu">
                  <li className="">
                    <a className="sidenav-item-link" href="new-order">
                      <span className="nav-text">New Order</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className={`has-sub ${isSubMenuOpen.pincode ? 'expand' : ''}`}>
              <a href="main-pincodes" className="sidenav-item-link">
                <i className="mdi mdi-map-marker"></i>
                <span className="nav-text">Available Pincodes</span>
              </a>
            </li>
            <li className={`has-sub ${isSubMenuOpen.deliverycharges ? 'expand' : ''}`}>
              <a href="delivery-charges" className="sidenav-item-link">
                <i className="mdi mdi-currency-inr"></i>
                <span className="nav-text">Delivery Charges</span>
              </a>
            </li>
            <li className={`has-sub ${isSubMenuOpen.products ? 'expand' : ''}`}>
              <a className="sidenav-item-link" href="#" onClick={() => toggleSubMenu('coupon')}>
                <i className="mdi mdi-ticket-percent"></i>
                <span className="nav-text">Coupon</span>
                <b className="caret"> </b>
              </a>
              <div className={`collapse ${isSubMenuOpen.coupon ? 'show' : ''}`}>
                <ul className="sub-menu" id="vendors" data-parent="#sidebar-menu">
                  <li className="">
                    <a className="sidenav-item-link" href="coupon">
                      <span className="nav-text">Add Coupon</span>
                    </a>
                  </li>
                  <li className="">
                    <a className="sidenav-item-link" href="ListCoupons">
                      <span className="nav-text">List Coupon</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className={`has-sub ${isSubMenuOpen.tcondition ? 'expand' : ''}`}>
              <a className="sidenav-item-link" href="#" onClick={() => toggleSubMenu('tcondition')}>
                <i className="mdi mdi-image-filter-none"></i>
                <span className="nav-text">Other Pages</span>
                <b className="caret"></b>
              </a>
              <div className={`collapse ${isSubMenuOpen.tcondition ? 'show' : ''}`}>
                <ul className="sub-menu" id="categorys" data-parent="#sidebar-menu">
                  <li className="">
                    <a className="sidenav-item-link" href="about-us">
                      <span className="nav-text">About Us</span>
                    </a>
                  </li>
                  <li className="">
                    <a className="sidenav-item-link" href="privacy-policy">
                      <span className="nav-text">Privacy Policy</span>
                    </a>
                  </li>
                  <li className="">
                    <a className="sidenav-item-link" href="customer-service">
                      <span className="nav-text">Customer Service</span>
                    </a>
                  </li>
                  <li className="">
                    <a className="sidenav-item-link" href="terms-conditions">
                      <span className="nav-text">Terms & Conditions</span>
                    </a>
                  </li>
                  <li className="">
                    <a className="sidenav-item-link" href="terms-conditions-seller">
                      <span className="nav-text">Terms & Conditions for Seller</span>
                    </a>
                  </li>
                  <li className="">
                    <a className="sidenav-item-link" href="agreed-process">
                      <span className="nav-text">Agreed Process</span>
                    </a>
                  </li>
                  <li className="">
                    <a className="sidenav-item-link" href="contact-us">
                      <span className="nav-text">Contact Us</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className={`has-sub ${isSubMenuOpen.cdetails ? 'expand' : ''}`}>
              <a href="CurrencyPage" className="sidenav-item-link">
                <i className="mdi mdi-cash"></i>
                <span className="nav-text">Currency</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

  );
}
export default SideBar;