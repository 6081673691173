import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { Input, Button, Modal, Switch, Table, Select,notification } from 'antd';
import './style.scss';
import './riot_admin.scss';
import SideBar from './sidebar';
import Header from './Header';
import EditProductModal from './EditProduct';
import RestockModal from './RestockModal';
import noImage from "./images/noimage.jpeg";
import "../src/pagination.css";

const { Option } = Select;
const { Search } = Input;
const DeleteCategoryModal = ({ visible, onCancel, onConfirm }) => {
  return (
    <Modal
      open={visible}
      title="Confirm Deletion"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="delete" type="primary" onClick={onConfirm}>
          Delete
        </Button>,
      ]}
    >
      <p>Are you sure you want to delete this product?</p>
    </Modal>
  );
};

const ListProduct = () => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [productGroupNumberToCount, setProductGroupNumberToCount] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [categoryList, setCategoriesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deactivateModalVisible, setDeactivateModalVisible] = useState(false);
  const [restockProductId, setRestockProductId] = useState(null);
  const [restockModalVisible, setRestockModalVisible] = useState(false);
  const [reasonForDeactivation, setReasonForDeactivation] = useState('');
  const [showActivateConfirmationModal, setShowActivateConfirmationModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [categoryDataforFilter, setCategoryDataforFilter] = useState([]);
const [selectedImage, setSelectedImage] = useState(null);
const [selectedType, setSelectedType] = useState('all');
const [cancellationReason, setCancellationReason] = useState('');
const [cancelModalVisible, setCancelModalVisible] = useState(false);
const [approveModalVisible, setApproveModalVisible] = useState(false);
const [searchQuery, setSearchQuery] = useState('');
const [selectedProductTypeId, setSelectedProductTypeId] = useState('');
const [currentPage, setCurrentPage] = useState(0);
const [totalPages, setTotalPages] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
const handleImageClick = (image) => {
  setSelectedImage(image);
};

// Function to close the image modal
const handleCloseImageModal = () => {
  setSelectedImage(null);
};
const handleSearch = (value) => {
  setSearchQuery(value);
};
  useEffect(() => {
    if (!token) {
      navigate('/AdminLogin');
      return;
    }
    fetchData(0);
  }, []); 
  useEffect(() => {
    if (searchQuery === '') {
      fetchData(0);
    } else {
      searchProduct();
    }
  }, [searchQuery]);
  const searchProduct = async () => {
    try {
      setLoading(true);
      // Make the API call
      const response = await fetch(`https://api.starmallonline.com/api/v1/product/searchadmin`, {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search: searchQuery,
          currency:localStorage.getItem('selectedCurrency'),
        }),
      });
      if (response.status === 200) {
        const searchData = await response.json();
        if (Array.isArray(searchData)) {
          setData(searchData);
        }
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchData = async (page) => {
    try {
      setLoading(true);
      const response = await axios.put('https://api.starmallonline.com/api/v1/product/get', {page:page}, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = response.data.data;
      const newProductGroupNumberToCount ={};
      data.forEach(product => {
        const productGroupNumber = product.productgroupnumber;
        newProductGroupNumberToCount[productGroupNumber] = (newProductGroupNumberToCount[productGroupNumber] || 0) + 1;
      });
     setProductGroupNumberToCount(newProductGroupNumberToCount);
      setData(response.data.data);
      setCurrentPage(response.data.Currentpageno)
      setTotalPages(response.data.Totalpageno)
      
      if(response.data.Totalpageno <= 1) {
        setPageNumbers([]);
      }else{

      const numbersArray = Array.from({ length: response.data.Totalpageno }, (_, index) => index + 1);
      setPageNumbers(numbersArray);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }finally {
      setLoading(false);
    }
  };

  const handleEditCategory =async (product) => {
    const fileids = product.fileModel != null ? product.fileModel.map((file) => file.fileid) : [];
    const previewfileids = product.previewimg != null ? product.previewimg.fileid : "";

    // Store fileids in sessionStorage
    sessionStorage.setItem('fileids', fileids !== null ? JSON.stringify(fileids) : JSON.stringify([]));
    sessionStorage.setItem('previewfileids', previewfileids);
    localStorage.setItem('selectedProductid', product.productid);
    localStorage.setItem('selectedQuantity', product.quantity);
    localStorage.setItem('selectedCurrency', product.currency);
    setSelectedProduct(product);
    try {
      const response = await axios.put('https://api.starmallonline.com/api/v1/product/getcategoryAndsubcategory', {},{
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        navigate('/AdminLogin');
        return; // Exit early to prevent further logic execution
      }
      const selectedCategory = response.data.find(category => category.categoryid === product.categoryid);
      if (selectedCategory) {
        setSelectedProductTypeId(selectedCategory.producttypeid || '');
        console.log("yhn",selectedCategory.producttypeid)
        setShowEditModal(true);
      } else {
        console.error('Category not found for product:', product);
      }
    }
    catch (error) {
      console.error('Error fetching category data:', error);
    }
  };

  const handleCloseEditModal = () => {
    setSelectedProduct(null);
    setShowEditModal(false);
  };

  const handleSaveEdit = async (editedData) => {
    try {
      setLoading(true);
      editedData.categoryid = selectedCategory.categoryid;
      editedData.productid = selectedProduct.productid;
      // editedData.subcategoryid = selectedProduct.subcategoryid;
      editedData.categoryname = selectedCategory.categoryname;
      delete editedData.fileModel;

      const response = await axios.post(
        'https://api.starmallonline.com/api/v1/product/edit',
        editedData,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        navigate('/AdminLogin');
        return;
      }
    if (selectedType === 'featuredproduct') {
      handleFeaturedClick(currentPage); // Call function to filter featured products
    } else if (selectedType === 'bestseller') {
      handleBestSellerClick(currentPage); // Call function to filter bestseller products
    } else {
      fetchData(currentPage); // Call function to fetch all products
    }
      if (response.status === 200) {
        console.log('Subcategory updated successfully');
      } else {
        console.error('PUT request failed');
      }
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setLoading(false);
      handleCloseEditModal(); // Close the modal whether the request succeeds or fails
    }
  };

  const handleDeleteCategoryConfirmed = async () => {
    try {
      setLoading(true);

      // Make an API request to delete the category
      const response = await axios.post(
        `https://api.starmallonline.com/api/v1/product/delete`,
        {
          categoryid: selectedCategory,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        notification.success({
          message: 'Product deleted successfully',
          duration: 3, // Duration in seconds
        });
        // Refresh the page or update the category list
        fetchData(currentPage);
      } else {
        console.error('Failed to delete category');
      }
    } catch (error) {
      console.error('Error deleting category:', error);
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
    }
  };

  const handleDeleteCategory = (productId) => {
    setSelectedCategory(productId);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    const fetchCategoryNames = async () => {
      try {
        const response = await axios.put('https://api.starmallonline.com/api/v1/category/get', {}, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.status === 401 || response.status === 404) {
          // Redirect to the login page for 401 or 404 errors
          navigate('/AdminLogin');
          return; // Exit early to prevent further logic execution
        }
        setCategoriesList(response.data);
      } catch (error) {
        console.error('Error fetching names:', error);
      }
    };

    fetchCategoryNames();
  }, []);

  useEffect (() => {
    const fetchAllCategories = async () => {
      try {
        const response = await axios.put('https://api.starmallonline.com/api/v1/product/getcategoryAndsubcategory', {},{
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.status === 401 || response.status === 404) {
          // Redirect to the login page for 401 or 404 errors
          navigate('/AdminLogin');
          return; // Exit early to prevent further logic execution
        }
        setCategoryDataforFilter(response.data);
      }
      catch (error) {
        console.error('Error fetching names:', error);
      }
    };
    fetchAllCategories();
  }, []);

  const handleActivateDeactivate = async (productid, value) => {
    if (value === false) {
      setSelectedProductId(productid);
      setDeactivateModalVisible(true);
    } else {
      setSelectedProductId(productid);
      setShowActivateConfirmationModal(true);
    }
  };

  const handleActivateDeactivateConfirmed = async () => {
    try {
      const response = await axios.post(
        'https://api.starmallonline.com/api/v1/product/activatedeactivateproduct',
        {
          productid: selectedProductId,
          productactivated: true,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );
      notification.success({
        message: response.data.description,
        duration: 3, // Duration in seconds
      });
      if (response.status === 200) {
        console.log('Product activated successfully');
        fetchData(currentPage);
      } else {
        console.error('Failed to activate product. Status:', response.status);
      }
    } catch (error) {
      console.error('Error activating product:', error);
    } finally {
      setShowActivateConfirmationModal(false);
    }
  };

  const handleDeactivateWithReason = async () => {
    try {
      const response = await axios.post(
        'https://api.starmallonline.com/api/v1/product/activatedeactivateproduct',
        {
          productid: selectedProductId,
          productactivated: false,
          productcancelled: reasonForDeactivation,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );
      notification.success({
        message: response.data.description,
        duration: 3, // Duration in seconds
      });
      if (response.status === 200) {
        console.log('Product deactivated successfully');
        fetchData(currentPage);
      } else {
        console.error('Failed to deactivate product. Status:', response.status);
      }
    } catch (error) {
      console.error('Error deactivating product:', error);
    } finally {
      setDeactivateModalVisible(false);
      setReasonForDeactivation('');
    }
  };

  const handleDuplicate = (row) => {
    const productgroupnumber = row.productgroupnumber;

    localStorage.setItem('duplicatedProductGroupNumber', productgroupnumber);

    navigate('/DuplicateProduct');
  };

  const handleRestock = (row) => {
    const productId = row.productid;
    localStorage.setItem('selectedProductidForRestock', productId);
    setRestockModalVisible(true);
  };
  const getCategoryFilters = () => {
    const filters = [];

    categoryDataforFilter.forEach(category => {
      const mainCategoryFilter = {
        text: category.categoryname,
        value: category.categoryname,
      };

      if (category.subcategories && category.subcategories.length > 0) {
        mainCategoryFilter.children = category.subcategories.map(subcategory => ({
          text: subcategory.subcategoryname,
          value: subcategory.subcategoryname,
        }));
      }

      filters.push(mainCategoryFilter);
    });

    return filters;
  };
  const handleBestSellerClick = (page) => {
    fetchBestSellerProducts(page);
  }
  const fetchBestSellerProducts = async (page) => {
    try {
      const response = await axios.put('https://api.starmallonline.com/api/v1/product/getbestsellers', {page:page,
      noofproducts:10,}, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = response.data.data;
      const newProductGroupNumberToCount ={};
      data.forEach(product => {
        const productGroupNumber = product.productgroupnumber;
        newProductGroupNumberToCount[productGroupNumber] = (newProductGroupNumberToCount[productGroupNumber] || 0) + 1;
      });
     setProductGroupNumberToCount(newProductGroupNumberToCount);
      setData(response.data.data);
      setCurrentPage(response.data.Currentpageno)
      setTotalPages(response.data.Totalpageno)
      
      if(response.data.Totalpageno <= 1) {
        setPageNumbers([]);
      }else{

      const numbersArray = Array.from({ length: response.data.Totalpageno }, (_, index) => index + 1);
      setPageNumbers(numbersArray);
      }
    } catch(error) {
        console.log('Error fetching bsetseller products: ', error);
    }
  };
  const handleFeaturedClick = (page) => {
    fetchFeaturedProducts(page);
  }
  const fetchFeaturedProducts = async (page) => {
    try {
      setLoading(true);
      const response = await axios.put('https://api.starmallonline.com/api/v1/product/getfeaturedproduct', {page:page,
      noofproducts:10,}, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 401 || response.status === 404) {
        navigate('/AdminLogin');
        return;
      }
      const data = response.data.data;
      const newProductGroupNumberToCount ={};
      data.forEach(product => {
        const productGroupNumber = product.productgroupnumber;
        newProductGroupNumberToCount[productGroupNumber] = (newProductGroupNumberToCount[productGroupNumber] || 0) + 1;
      });
     setProductGroupNumberToCount(newProductGroupNumberToCount);
      setData(response.data.data);
      setCurrentPage(response.data.Currentpageno)
      setTotalPages(response.data.Totalpageno)
      
      if(response.data.Totalpageno <= 1) {
        setPageNumbers([]);
      }else{

      const numbersArray = Array.from({ length: response.data.Totalpageno }, (_, index) => index + 1);
      setPageNumbers(numbersArray);
      }
    } catch(error) {
        console.log('Error fetching bsetseller products: ', error);
    } finally{
      setLoading(false);
    }
  };
  const handleTypeSelect = (value) => {
    setSelectedType(value); // Update selected type
    if (value === 'featuredproduct') {
      handleFeaturedClick(0); // Call function to filter featured products
    } else if (value === 'bestseller') {
      handleBestSellerClick(0); // Call function to filter bestseller products
    } else {
      fetchData(0); // Call function to fetch all products
    }
  };
  const handleCancellationReasonChange = (e) => {
    setCancellationReason(e.target.value);
  };
  const handleCancelProduct = (productId) => {
    setSelectedProductId(productId);
    setCancelModalVisible(true);
  };
  const handleConfirmCancelProduct = async () => {
    try {
      // Make API call to cancel the product
      const response = await axios.post(
        'https://api.starmallonline.com/api/v1/product/cancel',
        {
          productid: selectedProductId,
          cancelreason: cancellationReason,
          cancel:true,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );
      notification.success({
        message: response.data.description,
        duration: 3, // Duration in seconds
      });
  
      // Close modal and refresh data
      setCancelModalVisible(false);
      fetchData(currentPage);
    } catch (error) {
      // Handle error
      console.error('Error cancelling product:', error);
    } finally {
      // Clear reason and selected product
      setCancellationReason('');
      setSelectedProductId(null);
    }
  };
  const handleConfirmApproveProduct = async () => {
    try {
      // Make API call to approve the product
      const response = await axios.post(
        'https://api.starmallonline.com/api/v1/product/cancel',
        {
          productid: selectedProductId,
          cancel: false,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );
      notification.success({
        message: response.data.description,
        duration: 3, // Duration in seconds
      });
  
      // Close modal and refresh data
      setApproveModalVisible(false);
      fetchData(currentPage);
    } catch (error) {
      // Handle error
      console.error('Error approving product:', error);
    } finally {
      // Clear selected product
      setSelectedProductId(null);
    }
  };
  const handleApproveProductAfterCancel = (productId) => {
    setSelectedProductId(productId);
    setApproveModalVisible(true);
  };
  const columns = [
    {
      title: 'Category',
      dataIndex: 'categoryname',
      key: 'categoryname',
      sorter: (a, b) => a.categoryname.localeCompare(b.categoryname),
      filters: getCategoryFilters(), 
      onFilter: (value, record) => record.categoryname === value,
    },
    {
      title: 'SubCategory',
      dataIndex: 'subcategoryname',
      key: 'subcategoryname',
    },
    {
      title: 'SubSubCategory',
      dataIndex: 'subsubcategoryname',
      key: 'subsubcategoryname',
    },
    {
      title: 'Product Name',
      dataIndex: 'productname',
      key: 'productname',
      sorter: (a, b) => a.productname.localeCompare(b.productname),
    },
    {
      title: 'MRP Price',
      dataIndex: 'mrpprice',
      key: 'mrpprice',
      sorter: (a, b) => a.mrpprice - b.mrpprice,
    },
    {
      title: 'Selling Price',
      dataIndex: 'sellingprice',
      key: 'sellingprice',
      sorter: (a, b) => a.sellingprice - b.sellingprice,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: 'Image',
      dataIndex: 'previewimg.file',
      render: (text,record) =>
      record.previewimg && record.previewimg.file ? (
      <img src={record.previewimg.file} alt="Product Preview" width={"100px"} height={"100px"} onClick={() => handleImageClick(record.previewimg.file)}/>
      ) : (
        <img src={noImage} width={"100px"} height={"100px"}/>
      )
    },
    {
      title: 'Date',
      dataIndex: 'createDate',
      key: 'createDate',
      render: (text, record) => {
        const timestamp = record.createDate; // Assuming `createDate` is the timestamp provided in your data
        const date = new Date(timestamp);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based
        const year = date.getFullYear();
        // Get hours, minutes, seconds
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
    
        // Convert hours to 12-hour format and determine AM/PM
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    
        // Add leading zero if minutes/seconds are less than 10
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
    
        // Construct the formatted time string
        const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
        const formattedDate = `${year}-${month}-${day}`;
        const formattedDateTime = `${formattedDate} ${formattedTime}`;
        return formattedDateTime;
      }
    },
    
    {
      title: 'Varient',
      key: 'duplicate',
      render: (text, row) => (
        <Button type="primary" onClick={() => handleDuplicate(row)}>
           {productGroupNumberToCount[row.productgroupnumber] || 0}
        </Button>
      ),
    },
    {
      title: 'Restock',
      key: 'restock',
      render: (text, row) => (
        <Button type="primary" onClick={() => handleRestock(row)}>
          Restock
        </Button>
      ),
    },
    {
      title: 'Status',
      key: 'activateDeactivate',
      render: (text, row) => (
        <>
          <Switch
            checked={row.productactivated}
            onClick={() => handleActivateDeactivate(row.productid, !row.productactivated)}
          >
            {row.productactivated ? 'Deactivate' : 'Activate'}
          </Switch>
          {row.productactivated && row.productid === selectedProductId && (
            <Modal
              open={deactivateModalVisible}
              title="Deactivate Product"
              onCancel={() => setDeactivateModalVisible(false)}
              footer={[
                <Button key="cancel" onClick={() => setDeactivateModalVisible(false)}>
                  Cancel
                </Button>,
                <Button key="deactivate" type="primary" onClick={handleDeactivateWithReason}>
                  Deactivate
                </Button>,
              ]}
            >
              <Input
                placeholder="Enter reason for deactivation"
                value={reasonForDeactivation}
                onChange={(e) => setReasonForDeactivation(e.target.value)}
              />
            </Modal>
          )}
        </>
      ),
    },
    {
      title: 'Cancel/Approve',
      key: 'testing',
      render: (text, row) => (
        <>
           {row.cancel == false&& (
        <Button type="link" style={{ marginLeft: 8, color: 'red' }} onClick={() => handleCancelProduct(row.productid)}>Approve</Button>
      )}
      {row.cancel == true && (
        <Button style={{ marginLeft: 8, color: 'blue' }} type="link" onClick={() => handleApproveProductAfterCancel(row.productid)}>Cancelled</Button>
      )}
      {
        row.cancel == null && (
          <>
          <Button type="link" style={{ marginLeft: 8, color: 'red' }} onClick={() =>handleApproveProductAfterCancel(row.productid)} >Approve</Button>
          <Button style={{ marginLeft: 8, color: 'blue' }} type="link" onClick={() => handleCancelProduct(row.productid)}>Cancelled</Button>
          </>
        )
      }
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (text, row) => (
        <div className="btn-group">
          <a
            href="#"
            className="btn btn-outline-success"
            style={{ width: '100px' }}
            onClick={() => handleEditCategory(row)}
          >
            Edit
          </a>
          <a
            href="#"
            className="btn btn-outline-danger"
            style={{ width: '100px' }}
            onClick={() => handleDeleteCategory(row.productid)}
          >
            Delete
          </a>
        </div>
      ),
    },
  ];

  const activationConfirmationModal = (
    <Modal
      open={showActivateConfirmationModal}
      title="Confirm Activation"
      onCancel={() => setShowActivateConfirmationModal(false)}
      footer={[
        <Button key="cancel" onClick={() => setShowActivateConfirmationModal(false)}>
          Cancel
        </Button>,
        <Button key="confirm" type="primary" onClick={handleActivateDeactivateConfirmed}>
          Confirm
        </Button>,
      ]}
    >
      <p>Are you sure you want to activate this product?</p>
    </Modal>
  );

  const changePage = async (pageNumber) => {
    if (pageNumber >= 0 && pageNumber <= totalPages) {
      
    if (selectedType === 'featuredproduct') {
      handleFeaturedClick(pageNumber); // Call function to filter featured products
    } else if (selectedType === 'bestseller') {
      handleBestSellerClick(pageNumber); // Call function to filter bestseller products
    } else {
      fetchData(pageNumber); // Call function to fetch all products
    }
    }
  };
  const renderPageNumbers = () => {
    const maxVisiblePages = 6;
    const delta = Math.floor(maxVisiblePages / 2);
  
    if (totalPages <= maxVisiblePages) {
      return pageNumbers.map((number) => (
        <li
          key={number}
          className={currentPage === number - 1 ? "selected-page" : ""}
          onClick={() => changePage(number - 1)}
        >
          {number}
        </li>
      ));
    }
  
    if (currentPage <= delta) {
      return (
        <>
          {pageNumbers.slice(0, maxVisiblePages - 1).map((number) => (
            <li
              key={number}
              className={currentPage === number - 1 ? "selected-page" : ""}
              onClick={() => changePage(number - 1)}
            >
              {number}
            </li>
          ))}
          <li>...</li>
          <li onClick={() => changePage(totalPages - 1)}
          >
            {totalPages}
          </li>
        </>
      );
    }
  
    if (currentPage >= totalPages - delta) {
      return (
        <>
          <li
            style={{ marginRight: '10px', cursor: 'pointer' }}
            onClick={() => changePage(0)}
          >
            1
          </li>
          <li>...</li>
          {pageNumbers.slice(totalPages - maxVisiblePages + 1).map((number) => (
            <li
              key={number}
              className={currentPage === number - 1 ? "selected-page" : ""}
              style={{ marginRight: '10px', cursor: 'pointer' }}
              onClick={() => changePage(number - 1)}
            >
              {number}
            </li>
          ))}
        </>
      );
    }
    return (
      <>
        <li
          style={{ marginRight: '10px', cursor: 'pointer' }}
          onClick={() => changePage(0)}
        >
          1
        </li>
        <li>...</li>
        {pageNumbers.slice(currentPage - delta, currentPage + delta).map((number) => (
          <li
            key={number}
            className={currentPage === number - 1 ? "selected-page" : ""}
            style={{ marginRight: '10px', cursor: 'pointer' }}
            onClick={() => changePage(number - 1)}
          >
            {number}
          </li>
        ))}
        <li>...</li>
        <li
          style={{ marginRight: '10px', cursor: 'pointer' }}
          onClick={() => changePage(totalPages - 1)}
        >
          {totalPages}
        </li>
      </>
    );
  };
  return (
    <div className="wrapper">
      {/* LEFT MAIN SIDEBAR */}
      <SideBar />

      {/* PAGE WRAPPER */}
      <div className="ec-page-wrapper">
        {/* Header */}
        <Header />
        {/* CONTENT WRAPPER */}
        <div className="ec-content-wrapper">
          <div className="content" >
            <div className="breadcrumb-wrapper d-flex align-items-center justify-content-between">
              <div className='col-8'>
                <h1>Product</h1>
              </div>
              <Search
                placeholder="Search products"
                allowClear
                enterButton="Search"
                size="middle"
                onSearch={handleSearch}
              />
              <div>
                <Select defaultValue={"Select any Type"} onChange={handleTypeSelect}>
                  <Option value="featuredproduct">Flash Selling</Option>
                  <Option value="bestseller">Latest Product</Option>
                  <Option value="all">All</Option>
                </Select>
              </div>
              <div>
                <Link to="/product-add" className="btn btn-primary">
                  Add Product
                </Link>
              </div>
            </div>
            {loading  ? (
  <div className="container-fluid">
    <div className="row">
      <div className="muti-col-loader"></div>
    </div>
  </div>
) : (
            <div className="row">
              <div className="col-12">
                <div className="card card-default">
                  <div className="card-body">

                    <Table
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      scroll={{ x: 'max-content' }}
                    />
                    
      {totalPages > 1 && (
  <ul className="d-flex justify-content-center pagination">
    <li style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => changePage(currentPage - 1)}>
      {'<'}
    </li>
        {renderPageNumbers()}
    <li style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => changePage(currentPage + 1)}>
      {'>'}
    </li>
  </ul>
)}
                  </div>
                </div>
              </div>
            </div>
)}
          </div>
        </div>

        {selectedProduct && (
          <EditProductModal
            show={showEditModal}
            onHide={handleCloseEditModal}
            onSave={(editedData) => handleSaveEdit(editedData, fetchData)}
            categoryList={categoryList}
            category={selectedCategory}
            product={selectedProduct}
            productid={localStorage.getItem('selectedProductid')}
            quantity={localStorage.getItem('selectedQuantity')}
            selectedProductTypeId={selectedProductTypeId} 
          />
        )}
        {selectedCategory && (
          <DeleteCategoryModal
            visible={showDeleteModal}
            onCancel={() => setShowDeleteModal(false)}
            onConfirm={handleDeleteCategoryConfirmed}
          />
        )}
        {activationConfirmationModal}
        <RestockModal
          open={restockModalVisible}
          onCancel={() => setRestockModalVisible(false)}
          onAddRestock={(quantity) => {
            console.log('Add Restock button clicked with value:', quantity);
            setRestockModalVisible(false);
          }}
          onRemoveRestock={() => {
            console.log('Remove Restock button clicked for productid:');
            setRestockModalVisible(false);
          }}
          productid={localStorage.getItem('selectedProductidForRestock')}
          fetchData={fetchData}
        />
        <Modal
        open={!!selectedImage}
        onCancel={handleCloseImageModal}
        footer={null}
        width={1200}
      >
        <img src={selectedImage} alt="Selected Banner" style={{ width: '100%' }} />
      </Modal>
      <Modal
  open={cancelModalVisible}
  title="Cancel Product"
  onCancel={() => setCancelModalVisible(false)}
  onOk={handleConfirmCancelProduct}
>
  <Input
    placeholder="Enter reason for cancellation"
    value={cancellationReason}
    onChange={handleCancellationReasonChange}
  />
</Modal>
<Modal
  open={approveModalVisible}
  title="Approve Product"
  onCancel={() => setApproveModalVisible(false)}
  onOk={handleConfirmApproveProduct}
>
  <p>Are you sure you want to approve this product?</p>
</Modal>
      </div>
    </div>
  );
};

export default ListProduct;
