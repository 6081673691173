import React, { useState, useEffect } from 'react';
import '../style.scss';
import '../riot_admin.scss';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'antd';
import axios from 'axios';
import { Select,DatePicker  } from 'antd';
import LoginHeader from '../componant/LoginHeader';

const Stage2 = () => {
  const token = sessionStorage.getItem('tokenforseller');
  const navigate = useNavigate();
  const [businessname, setPan] = useState('');
  const [businessadress, setAadhar] = useState('');
  const [businesstype, setBusinessType] = useState('');
  const [businesspan, setbusinesspan] = useState('');
  const [accdetails, setAccdetails] = useState('');
  const [accname, setAccname] = useState('');
  const [accnumber, setAccnumber] = useState('');
  const [ifsccode, setifsccode] = useState('');
  const [branch, setBranch] = useState('');
  const [data, setData] = useState('');
  const [gst, setGst] = useState('');
  const [clientId, setClientId] = useState(null);
  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          'https://api.starmallonline.com/api/v1/login/getuserdetails',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
            },
          }
        );

        if (response.ok) {
            const responseData = await response.json();
            setClientId(responseData.id); // Store the client ID in state
          } else {
            console.error('Error fetching client details');
          }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    fetchClientDetails();
  }, []);
const onFinish = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
  
    try {
      // TODO: Replace with actual API call
      const response = await fetch("https://api.starmallonline.com/api/v1/seller/edit", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Authorization:  `Bearer ${sessionStorage.getItem('tokenforseller')}`,
        },
        body: JSON.stringify({
          sellerid:clientId,
            businessname,
        businessadress,
        businesstype,
        businesspan,
        accdetails,
        accname,
        accnumber,
        ifsccode,
        branch,
        gst,
        }),
      });
  
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        // navigate('/login');
        return; // Exit early to prevent further logic execution
      }
  
      if (response.ok) {
        const data = await response.json();
        console.log("POST request successful");
        if (data.status === "Success") {
          // Only navigate to '/Stage3' if the response status is "Success"
          navigate('/Stage3');
        }
        if (response.data && response.data.length > 0) {
            setData(response.data);
          } else {
            setData([]); // If no data is received, set an empty array
          }
        // window.location.reload(); // Refresh the page
        // Optionally, you can update your component state or perform other actions here
      } else {
        console.error("POST request failed");
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  const handleLogout = () => {
    sessionStorage.clear();
  navigate('/AdminLogin');
  };
  return (
    <div>
    <LoginHeader/>
    <div className='registration-container'>
        <div className='container text-center pb-4'>
       <p className='fs-4'> Thank you for choosing Shopeasey as your platform for selling. We appreciate your interest in joining our community of sellers. 

During the registration process, we are carefully reviewing your information to ensure the highest quality experience for both sellers and buyers.</p>
        </div>
        <div className='registration-process  container d-flex justify-content-center'>
          <div className='registration-process-done'>
          <span> 1 </span> Personal Details
            </div>
          <div className='registration-process-active'>
          <span> 2 </span> Business Details
          </div>
          <div className='registration-process-pending'>
          <span> 3 </span> Plan & Payment
          </div>
        </div>
        <div className="d-flex justify-content-center">
        <div className="registration">
          
                      <form onSubmit={onFinish}>
                         
                        <div className="form-group row">
                          <label htmlFor="text" className="col-12 col-form-label">
                          Business Name
                          </label>
                          <div className="col-12">
                            <input
                              id="text"
                              name="text"
                              className="form-control here slug-title"
                              type="text"
                              value={businessname}
                              onChange={(e) => setPan(e.target.value)}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label htmlFor="slug" className="col-12 col-form-label">
                          Business Address
                          </label>
                          <div className="col-12">
                            <input
                              id="slug"
                              name="slug"
                              className="form-control here set-slug"
                              type="text"
                              value={businessadress}
                              onChange={(e) => setAadhar(e.target.value)}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group row">
  <label htmlFor="businessType" className="col-12 col-form-label">
    Business Type
  </label>
  <div className="col-12">
    <select
      id="businessType"
      name="businessType"
      className="form-control here set-slug"
      value={businesstype }
      onChange={(e) => setBusinessType(e.target.value)}
      autoComplete="off"
      required
    >
      <option value="">Select Business Type</option>
      <option value="Corporation">Corporation</option>
      <option value="Limited liability company">Limited liability company</option>
      <option value="Cooperative">Cooperative</option>
      <option value="C corporation">C corporation</option>
      <option value="General partnership">General partnership</option>
      <option value="Private company">Private company</option>
      <option value="Limited company">Limited company</option>
      <option value="Sole proprietorship">Sole proprietorship</option>
      <option value="Partnership">Partnership</option>
    </select>
  </div>
</div>

                        <div className="form-group row">
                          <label htmlFor="slug" className="col-12 col-form-label">
                          Business Pan
                          </label>
                          <div className="col-12">
                            <input
                              id="slug"
                              name="slug"
                              className="form-control here set-slug"
                              type="text"
                              value={businesspan}
                              onChange={(e) => setbusinesspan(e.target.value)}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label htmlFor="slug" className="col-12 col-form-label">
                          Account Name
                          </label>
                          <div className="col-12">
                            <input
                              id="slug"
                              name="slug"
                              className="form-control here set-slug"
                              type="text"
                              value={accname}
                              onChange={(e) => setAccname(e.target.value)}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label htmlFor="slug" className="col-12 col-form-label">
                          Account Number
                          </label>
                          <div className="col-12">
                            <input
                              id="slug"
                              name="slug"
                              className="form-control here set-slug"
                              type="text"
                              value={accnumber}
                              onChange={(e) => setAccnumber(e.target.value)}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label htmlFor="slug" className="col-12 col-form-label">
                          IFSC Code
                          </label>
                          <div className="col-12">
                            <input
                              id="slug"
                              name="slug"
                              className="form-control here set-slug"
                              type="text"
                              value={ifsccode}
                              onChange={(e) => setifsccode(e.target.value)}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label htmlFor="slug" className="col-12 col-form-label">
                          Branch
                          </label>
                          <div className="col-12">
                            <input
                              id="slug"
                              name="slug"
                              className="form-control here set-slug"
                              type="text"
                              value={branch}
                              onChange={(e) => setBranch(e.target.value)}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label htmlFor="text" className="col-12 col-form-label">
                          GST
                          </label>
                          <div className="col-12">
                            <input
                              id="text"
                              name="text"
                              className="form-control here slug-title"
                              type="text"
                              value={gst}
                              onChange={(e) => setGst(e.target.value)}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <button name="submit" type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                      </div> </div> </div> </div>
  );
};

export default Stage2;
