// EditCategoryModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from "../src/editCnfirmation/ConfirmationModal";
import { notification } from 'antd';
const EditCategoryModal = ({
  category,
  show,
  onHide,
  onSave,
  groupapitypedata,
  fetchData,
}) => {
	const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Define state variables for form fields
  const [categoryname, setCategoryname] = useState(category.categoryname);
  const [slug, setSlug] = useState(category.slug);
  const [sortdescription, setSortdescription] = useState(category.sortdescription);
  const [fulldescription, setFulldescription] = useState(category.fulldescription);
  const [producttag, setProducttag] = useState(category.producttag);
  const [categorylocation, setcategorylocation] = useState(category.categorylocation);
  const [fileid, setFileid] = useState(null);
  const [selectedProductTypeId, setSelectedProductTypeId] = useState(category.producttypeid);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const handleShowConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const handleHideConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  useEffect(() => {
      // setFileid(category.fileModel ? category.fileModel.file : null);
      setSelectedProductTypeId(category.producttypeid ? category.producttypeid : null);
  },[category]);
  useEffect(() => {
		if (!token) {
		  // Redirect to the login page if the token is not present
		  navigate('/AdminLogin');
		  return;
		}
		fetchDataforadmin();
	  }, []); // Empty dependency array ensures the effect runs only once
	  const fetchDataforadmin = async () => {
		try {
		  const response = await axios.post("https://api.starmallonline.com/api/v1/producttype/getforadmin",{},{
			
		  headers: {
        Authorization: "Bearer " + token,
        'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };
  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    setFileid(imageFile);
  };
  

  
  const handleImageUpload = async () => {
    try {
      setLoading(true);
      if (fileid) {
        const formData = new FormData();
        formData.append("file", fileid);
  
        const uploadResponse = await axios.post(
          "https://api.starmallonline.com/api/v1/fileupload/file",
          formData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        if (uploadResponse.status === 401 || uploadResponse.status === 404) {
          navigate("/AdminLogin");
          return;
        }
  
        const newOrganiserId = uploadResponse.data.organiserId;
      setFileid(newOrganiserId);
      console.log("newOrganiserId",newOrganiserId);


      console.log("Image uploaded successfully");
      }
  
      setLoading(false);
    } catch (error) {
      console.error("Error uploading image:", error);
      setLoading(false);
    }
  };
  const handleSaveChanges = async () => {
    const updatedCategory = {
      categoryid: category.categoryid, // Include the category ID for identification
      categoryname,
      slug,
      sortdescription,
      fulldescription,
      producttag,
      categorylocation: parseInt(categorylocation),
      fileid,
    };
  
    try {
      setLoading(true);
  
      // Make an API request to update the category without uploading the image
      const response = await axios.post(
        "https://api.starmallonline.com/api/v1/category/edit",
        updatedCategory,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 401 || response.status === 404) {
        navigate("/AdminLogin");
        return;
      }
      notification.success({
        message: response.data.description,
        duration: 3, // Duration in seconds
      });
      onHide();
      fetchData();
      if (response.data.success) {
        console.log("Category updated successfully");
        onSave(updatedCategory); // Notify the parent component about the update
        // onHide(); // Close the modal

      } else {
        console.error("Failed to update category");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error updating category:", error);
      setLoading(false);
    }
  };
  
  const handleConfirmSaveChanges = () => {
    handleHideConfirmationModal();
    // Proceed with saving changes
    handleSaveChanges();
  };  
  return (
    <Modal show={show} onHide={onHide}>
      {/* Modal content */}
      <Modal.Header closeButton>
        <Modal.Title>Edit Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="categoryname">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="categoryname"
              value={categoryname}
              onChange={(e) => setCategoryname(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="slug">
            <Form.Label>Slug</Form.Label>
            <Form.Control
              type="text"
              name="slug"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
            />
            <small>
              The “slug” is the URL-friendly version of the name. It is usually
              all lowercase and contains only letters, numbers, and hyphens.
            </small>
          </Form.Group>

          <Form.Group controlId="sortdescription">
            <Form.Label>Title (META TAG)</Form.Label>
            <Form.Control
              as="textarea"
              name="sortdescription"
              value={sortdescription}
              onChange={(e) => setSortdescription(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="fulldescription">
            <Form.Label>Description (META TAG)</Form.Label>
            <Form.Control
              as="textarea"
              name="fulldescription"
              value={fulldescription}
              onChange={(e) => setFulldescription(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="producttag">
            <Form.Label>Product Tags</Form.Label>
            <Form.Control
              type="text"
              name="producttag"
              value={producttag}
              onChange={(e) => setProducttag(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="categorylocation">
            <Form.Label>Category Location</Form.Label>
            <Form.Control
              type="number"
              name="categorylocation"
              value={categorylocation}
              onChange={(e) => setcategorylocation(e.target.value)}
            />
          </Form.Group>
         <Form.Group controlId="fileid">
  <Form.Label>Select to replace Category Image</Form.Label>
  <Form.Control
              type="number"
              name="categorylocation"
              value={categorylocation}
              onChange={(e) => setcategorylocation(e.target.value)}
            />
  <Form.Control
    type="file"
    name="fileid"
    onChange={(e) => handleImageChange(e)}
  />
</Form.Group> <Button variant="primary" onClick={handleImageUpload} disabled={loading}>
  Upload Image
</Button>

<Form.Group controlId="producttypeid">
  <Form.Label>Group Type</Form.Label>
  <Form.Select
    value={selectedProductTypeId}
    onChange={(e) => setSelectedProductTypeId(e.target.value)}
    disabled
  >
    {groupapitypedata.map((groupType) => (
      <option key={groupType.producttypeid} value={groupType.producttypeid}>
        {groupType.type}
      </option>
    ))}
  </Form.Select>
</Form.Group>


        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleShowConfirmationModal} disabled={loading}>
          Save Changes
        </Button>
        
      </Modal.Footer>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleHideConfirmationModal}
        onConfirm={handleConfirmSaveChanges}
      />
    </Modal>
  );
};

export default EditCategoryModal;
