import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import './riot_admin.scss';
import SideBar from './sidebar';
import Header from './Header';
import ReactQuill from 'react-quill';
import axios from "axios";

const AboutUs = () => {
  const navigate = useNavigate();
  const [aboutus, setAboutus] = useState('');
  const [commonDetailsId, setCommonDetailsId] = useState(''); // Assuming you have a way to obtain this ID
  const [commonDetailsField, setCommonDetailsField] = useState('aboutus'); // Adjust as needed
  // Function to fetch common details
  const fetchCommonDetails = async () => {
    try {
      const payload = {
        commondetailsfield: 'aboutus',
      }
      const response = await axios.put("https://api.starmallonline.com/api/v1/commondetails/getcommondetails", payload, {

      });
      if (response.status === 200) {
        // Successfully fetched common details
        const commonDetails = response.data;
        setAboutus(commonDetails.commondetails);
        // Do something with commonDetails, such as updating your component's state
        console.log("Common Details:", commonDetails);

      } else {
        console.error("Failed to fetch common details");
      }
    } catch (error) {
      console.error("Error fetching common details:", error);
    }
  };

  // useEffect to fetch common details when the component mounts
  useEffect(() => {
    fetchCommonDetails();
  }, []);


  const [isSubMenuOpen, setIsSubMenuOpen] = useState({
    vendors: false,
    user: false,
    categories: false,
    products: false,
    orders: false,
    authentication: false,
    icons: false,
    otherpages: false,
  });
  const toggleSubMenu = (menuName) => {
    setIsSubMenuOpen((prevState) => ({
      ...prevState,
      [menuName]: !prevState[menuName],
    }));
  };
  // Function to edit common details
  const editCommonDetails = async () => {
    try {
      const payload = {
        commondetailsid: commonDetailsId, // Pass the common details ID you want to edit
        commondetails: aboutus,
        commondetailsfield: commonDetailsField,
      };

      const response = await axios.post("https://api.starmallonline.com/api/v1/commondetails/editcommondetails", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.status === 200) {
        // Successfully edited common details
        const editedCommonDetails = response.data;
        // Do something with the editedCommonDetails, if needed
        console.log("Edited Common Details:", editedCommonDetails);
      } else {
        console.error("Failed to edit common details");
      }
    } catch (error) {
      console.error("Error editing common details:", error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    editCommonDetails();
  };
  return (
    <div className="wrapper">

      {/* <!-- LEFT MAIN SIDEBAR --> */}
      <SideBar />

      <div className="ec-page-wrapper">
        {/* <!-- Header --> */}
        <Header />

        {/* <!-- CONTENT WRAPPER --> */}
        <div className="ec-content-wrapper">
          <div className="content" >
            <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
              <h1>About Us</h1>
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="ec-cat-list card card-default mb-24px">
                  <div className="card-body">
                    <div className="ec-cat-form">
                      <div className="row">
                        <div className="col-12" style={{ marginLeft: "90%" }}>
                          <button
                            name="edit"
                            type="button"
                            className="btn btn-primary float-right"
                            onClick={() => {
                              navigate('/EditAbout');
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                      <form
                      >
                        <div className="form-group row">
                          <label className="col-12 col-form-label">Description</label>
                          <div className="col-12" dangerouslySetInnerHTML={{ __html: aboutus }} />
                          {/* <div className="col-12">
													value={aboutus}
                          onChange={(value) => setAboutus(value)}
												</div> */}
                        </div>

                      </form>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default AboutUs;
