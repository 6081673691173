import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import "./riot_admin.scss";
import SideBar from "./sidebar";
import { Input } from "antd";
import Header from "./Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Select, Form, Upload, Button } from "antd";
import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ProductEdit = () => {
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  // Function to make the API request
  const getProductById = async (productid) => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        // Redirect to the login page if the token is not present
        return;
      }

      const response = await axios.put(
        "https://api.starmallonline.com/api/v1/product/getById",
        {
          productid: productid, // Pass the productid from localStorage in the payload
        }
      );

      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        navigate("/AdminLogin");
        return;
      }

      const productDetails = response.data; // Assuming your API response is an object
      setProductData({
        categoryname: productDetails.categoryname,
        subcategoryname: productDetails.subcategoryname,
        subsubcategoryname: productDetails.subsubcategoryname,
        producttags: productDetails.producttags,
        productname: productDetails.productname,
        slug: productDetails.slug,
        sortdescription: productDetails.sortdescription,
        fulldetails: productDetails.fulldetails,
        mrpprice: productDetails.mrpprice,
        sellingprice: productDetails.sellingprice,
        quantity: productDetails.quantity,
        color: productDetails.color,
        colorname: productDetails.colorname,
        weight: productDetails.weight,
        countryoforigin: productData.countryoforigin,
        options: productDetails.options,
        // Add more fields as needed
      });
      // Handle the response data here
      console.log("Product data:", response.data);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  // You can retrieve the productid from localStorage
  const productid = localStorage.getItem("editProductId");

  useEffect(() => {
    if (!productid) {
      // Handle the case where productid is not present in localStorage
      // You can redirect to a different page or show an error message
      console.error("Product ID not found in localStorage");
      return;
    }

    // Fetch product data using the productid
    getProductById(productid);
  }, [productid]);

  return (
    <div className="wrapper">
      <SideBar />
      <div className="ec-page-wrapper">
        <Header />
        <div className="ec-content-wrapper">
          <div className="content">
            <div className="breadcrumb-wrapper d-flex align-items-center justify-content-between">
              <div>
                <h1>Edit Product</h1>
                <p className="breadcrumbs">
                  <span>
                    <a href="index.html">Home</a>
                  </span>
                  <span>
                    <i className="mdi mdi-chevron-right"></i>
                  </span>
                  Product
                </p>
              </div>
              <div>
                <Link to="/product-list" className="btn btn-primary">
                  {" "}
                  View All
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card card-default">
                  <div className="card-header card-header-border-bottom">
                    {/* <h2>Add Product</h2> */}
                  </div>

                  <div className="card-body">
                    <div className="row ec-vendor-uploads">
                      <div className="col-lg-8">
                        <div className="ec-vendor-upload-detail">
                          <form>
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Select Category
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <Select
                                  style={{ width: "60%" }}
                                  value={productData.categoryname}
                                ></Select>
                              </div>
                              <div className="col-md-4">
                                <label className="form-label">
                                  Select SubCategory
                                </label>
                                <Select
                                  style={{ width: "60%" }}
                                  value={productData.subcategoryname}
                                ></Select>
                              </div>
                              <div className="col-md-4">
                                <label className="form-label">
                                  Select SubSubCategory
                                </label>
                                <Select
                                  style={{ width: "60%" }}
                                  value={productData.subsubcategoryname}
                                ></Select>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <label for="inputEmail4" className="form-label">
                                  Product Code
                                </label>
                                <input
                                  type="text"
                                  className="form-control slug-title"
                                  id="inputEmailc"
                                  value={productData.producttags}
                                />
                              </div>
                              <div className="col-md-6">
                                <label for="inputEmail4" className="form-label">
                                  Product Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control slug-title"
                                  id="inputEmail4"
                                  value={productData.productname}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <label
                                for="slug"
                                className="col-12 col-form-label"
                              >
                                Slug
                              </label>
                              <div className="col-12">
                                <input
                                  id="slug"
                                  name="slug"
                                  className="form-control here set-slug"
                                  type="text"
                                  value={productData.slug}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <label
                                for="slug"
                                className="col-12 col-form-label"
                              >
                                Country Of Origin
                              </label>
                              <div className="col-12">
                                <input
                                  id="slug"
                                  name="slug"
                                  className="form-control here set-slug"
                                  type="text"
                                  value={productData.countryoforigin}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <label className="form-label">Description</label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={productData.sortdescription}
                                onReady={(editor) => {
                                  // You can store the "editor" and use when it is needed.
                                  console.log(
                                    "Editor is ready to use!",
                                    editor
                                  );
                                }}
                                onBlur={(event, editor) => {
                                  console.log("Blur.", editor);
                                }}
                                onFocus={(event, editor) => {
                                  console.log("Focus.", editor);
                                }}
                              />
                            </div>
                            <div className="col-md-12">
                              <label className="form-label">
                                Full Description
                              </label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={productData.fulldetails}
                                onReady={(editor) => {
                                  // You can store the "editor" and use when it is needed.
                                  console.log(
                                    "Editor is ready to use!",
                                    editor
                                  );
                                }}
                                onBlur={(event, editor) => {
                                  console.log("Blur.", editor);
                                }}
                                onFocus={(event, editor) => {
                                  console.log("Focus.", editor);
                                }}
                              />
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <label className="form-label">MRP Price</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="price1"
                                  value={productData.mrpprice}
                                />
                              </div>
                              <div className="col-md-6">
                                <label className="form-label">
                                  Selling Price
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="price2"
                                  value={productData.sellingprice}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <label className="form-label">Quantity</label>
                              <input
                                type="number"
                                className="form-control"
                                id="quantity1"
                                value={productData.quantity}
                              />
                            </div>

                            <div className="col-md-12">
                              <label className="form-label" name="uploadfile">
                                Upload Images
                              </label>
                              <Form.Item name="uploadfile">
                                {/* <Upload
															customRequest={handleFileUpload} // Use the customRequest prop for custom upload handling
															fileList={fileList}
															onChange={({ fileList }) => setFileList(fileList)}
															>
															<Button icon={<UploadOutlined />} style={{top:'0px',borderRadius:'0px'}}>Click to Upload</Button>
														</Upload> */}
                              </Form.Item>
                              {/* <Upload
														customRequest={handleFileUpload} // Use the customRequest prop for custom upload handling
														fileList={fileList}
														onChange={({ fileList }) => setFileList(fileList)}
														>
											<Button icon={<UploadOutlined />} style={{top:'0px',borderRadius:'0px'}}>Click to Upload</Button>
										</Upload> */}
                            </div>

                            <div className="card-header card-header-border-bottom">
                              <h2>Additional Inputs</h2>
                            </div>
                            <div className="row">
                              <div className="col-md-3 mb-25">
                                <label className="form-label">Color</label>
                                <input
                                  type="color"
                                  className="form-control form-control-color"
                                  id="exampleColorInput1"
                                  value={productData.color}
                                />
                              </div>
                              <div className="col-md-3 mb-25">
                                <label className="form-label">
                                  Colour Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="weight"
                                  value={productData.colorname}
                                />
                              </div>
                              <div className="col-md-3 mb-25">
                                <label className="form-label">Weight</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="weight"
                                  value={productData.weight}
                                />
                              </div>
                              <div className="col-md-3 mb-25">
                                <label className="form-label">Unit</label>
                                <Select style={{ width: "60%" }}>
                                  <Select.Option value="Kg">Kg</Select.Option>
                                  <Select.Option value="G">g</Select.Option>
                                  <Select.Option value="L">L</Select.Option>
                                  <Select.Option value="ML">ml</Select.Option>
                                  value={productData.productunit}
                                  {/* Add more options as needed */}
                                </Select>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 mb-25">
                                <label className="form-label">Size</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="weight"
                                  value={productData.size}
                                />
                              </div>
                              <div className="col-md-6 mb-25">
                                <label className="form-label">Storage</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="weight"
                                  value={productData.storage}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mb-25">
                              <label className="form-label">Options</label>
                              <Select
                                mode="multiple" // Set mode to 'multiple' for multi-select
                                style={{
                                  width: "100%",
                                  border: "2px solid #8f8f8f",
                                }}
                                placeholder="Select options"
                                alue={productData.productposition}
                              ></Select>
                            </div>
                            <div className="col-md-12">
                              <label className="form-label">
                                Product Position
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                value={productData.productposition}
                              />
                            </div>

                            <div className="col-md-12">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductEdit;
