import React, { useState, useEffect } from "react";
import "./style.scss";
import "./riot_admin.scss";
import SideBar from "./sidebar";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const CustomerAdd = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [location, setLocation] = useState('');
    const chennaiAreas = [
        "Adyar",
        "Anna Nagar",
        "T. Nagar",
        "Velachery",
        "Kodambakkam",
        "Tambaram",
        "Besant Nagar",
        "Nungambakkam",
        "Guindy",
        "Porur"
    ];
    const [address, setAddress] = useState('');
    const [mobileno, setMobileno] = useState('');
    const [milkQuantity, setMilkQuantity] = useState('');
    const [deliverDay, setDeliverDay] = useState('');
    const daysOfWeek = [
        "Everyday",
        "Week Ends",
        "Week Days",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];
    const [role, setRole] = useState('');

    useEffect(() => {
        const storedName = localStorage.getItem('name');
        const storedRole = localStorage.getItem('role');
        const currentDate = new Date().toISOString().split('T')[0];
        if (storedName) {
            setName(storedName);
        }
        if (storedRole) {
            setRole(storedRole);
        }
        setDate(currentDate);
    }, []);

    const onFinish = async (e) => {
        e.preventDefault();
        if (!name || !date || !location || !address || !mobileno || !milkQuantity || !deliverDay) {
            alert('Please fill out all fields.');
            return;
        }
        const token = localStorage.getItem("token");

        try {
            setLoading(true);
            const response = await fetch(
                "https://api.starmallonline.com/api/customers/createcustomer",
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + token,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        id: localStorage.getItem('id'),
                        name: name,
                        date: date,
                        location: location,
                        address: address,
                        mobileno: mobileno,
                        milkQuantity: milkQuantity,
                        deliverDay: deliverDay,
                        role: role,
                    }),
                }
            );

            const data = await response.text();
            if (response.status === 401 || response.status === 404) {
                navigate("/login");
                return;
            }
            if (response.status == 201) {
                message.success("Customer added successfully");
                navigate('/customer-list');
            } else {
                setLoading(false);
                console.log(data.description);
            }
        } catch (error) {
            console.error("Error posting data:", error);
            setLoading(false);
        }
    };

    return (
        <div className="wrapper">
            <SideBar />
            <div className="ec-page-wrapper">
                <Header />
                <div className="ec-content-wrapper">
                    <div className="content">
                        <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
                            <h1>Customer</h1>
                        </div>
                        <div className="row">
                            <div className="col-xl-8 col-lg-12">
                                <div className="ec-cat-list card card-default mb-24px">
                                    <div className="card-body">
                                        <div className="ec-cat-form">
                                            <h4>ADD</h4>
                                            <form onSubmit={onFinish}>
                                                <div className="form-group row">
                                                    <label for="text" className="col-12 col-form-label">Customer Name</label>
                                                    <div className="col-12">
                                                        <input id="text" name="text" className="form-control here slug-title" type="text" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="text" className="col-12 col-form-label">Date</label>
                                                    <div className="col-12">
                                                        <input id="text" name="text" className="form-control here slug-title" type="text" value={date} onChange={(e) => setDate(e.target.value)} autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label className="col-form-label">Location</label>
                                                        <select
                                                            className="form-control"
                                                            value={location}
                                                            onChange={(e) => setLocation(e.target.value)}
                                                            required
                                                        >
                                                            <option value="" disabled>Select your location</option>
                                                            {chennaiAreas.map((area, index) => (
                                                                <option key={index} value={area}>
                                                                    {area}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label className="col-form-label">Delivery Day</label>
                                                        <select
                                                            className="form-control"
                                                            value={deliverDay}
                                                            onChange={(e) => setDeliverDay(e.target.value)}
                                                            required
                                                        >
                                                            <option value="" disabled>Select a delivery day</option>
                                                            {daysOfWeek.map((day, index) => (
                                                                <option key={index} value={day}>
                                                                    {day}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label className="col-form-label">Mobile No</label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            value={mobileno}
                                                            onChange={(e) => setMobileno(e.target.value)}
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label className="col-form-label">Milk Quantity</label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            value={milkQuantity}
                                                            onChange={(e) => setMilkQuantity(e.target.value)}
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label className="col-form-label">Address</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={address}
                                                            onChange={(e) => setAddress(e.target.value)}
                                                            autoComplete="off"
                                                            required
                                                            style={{
                                                                width: '100%', height: '100px',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <button name="submit" type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};
export default CustomerAdd;
