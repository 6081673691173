import React, { useState } from 'react';
import { Modal, Form, Input, Button,notification } from 'antd';
import axios from 'axios';

const RestockModal = ({ visible, onCancel, onAddRestock, onRemoveRestock, productid ,quantity, fetchData }) => {
  const [restockInputValue, setRestockInputValue] = useState('');
  const [validationError, setValidationError] = useState('');
  const [removeError, setRemoveError] = useState('');
;

const handleRemoveRestock = async () => {
    try {
      console.log('Removing restock for productid:', productid, 'quantity:', restockInputValue);
      if (parseInt(restockInputValue) > quantity) {
        setValidationError('Please enter a correct quantity.');
        return;
      }
  
      // Reset validation error
      setValidationError('');
      setRemoveError(''); // Reset remove error
  
      // Make the API call to remove restock
      const response = await axios.post(
        'https://api.starmallonline.com/api/v1/product/restock',
        {
          productid: productid,
          quantity: restockInputValue,
          remove: true,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );
      notification.success({
        message: response.data.description,
        duration: 3, // Duration in seconds
      });
      fetchData();
      if (response.status === 200) {
        console.log('Remove Restock API call successful');
        // Call the onRemoveRestock callback to handle any additional logic
        onRemoveRestock();
        onCancel(); // Close the modal on success
        // window.location.reload();
      } else {
        console.error('Failed to remove restock. Status:', response.status);
        setRemoveError(response.data.description); // Set remove error description
      }
    } catch (error) {
      console.error('Error removing restock:', error);
    }
  };
  
  const handleAddRestock = async () => {
    try {
        console.log('Removing restock for productid:', productid, 'quantity:', restockInputValue);

      // Make the API call to remove restock
      const response = await axios.post(
        'https://api.starmallonline.com/api/v1/product/restock',
        {
          productid: productid,
          quantity: restockInputValue,
          remove: null,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );
      notification.success({
        message: response.data.description,
        duration: 3, // Duration in seconds
      });
      fetchData();
      if (response.status === 200) {
        console.log('Remove Restock API call successful');
        // window.location.reload();
        onRemoveRestock();
      } else {
        console.error('Failed to remove restock. Status:', response.status);
      }
    } catch (error) {
      console.error('Error removing restock:', error);
    }
  };
  return (
    <Modal
      title="Restock Product"
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button key="removeRestock" type="primary" onClick={handleRemoveRestock}>
          Remove Restock
        </Button>,
        <Button key="addRestock" type="primary" onClick={handleAddRestock}>
          Add Restock
        </Button>,
      ]}
    >
      {/* Input field for restock quantity */}
      <Form.Item label="Restock Quantity">
        <Input
          value={restockInputValue}
          onChange={(e) => setRestockInputValue(e.target.value)}
        />
      </Form.Item>
      {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
      {removeError && <p style={{ color: 'red' }}>{removeError}</p>}
    </Modal>
  );
};

export default RestockModal;
