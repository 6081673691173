import React, { useState } from 'react';
import './style.scss';
import './riot_admin.scss';
import SideBar from './sidebar';
import { Input } from 'antd';
import Header from './Header';

const ProductGrid = () => {
    return(
        <div className="wrapper">
		{/* <!-- LEFT MAIN SIDEBAR --> */}
		<SideBar/>

		{/* <!-- PAGE WRAPPER --> */}
		<div className="ec-page-wrapper">

			{/* <!-- Header --> */}
			<Header/>

			{/* <!-- CONTENT WRAPPER --> */}
			<div className="ec-content-wrapper">
				<div className="content" >
					<div className="breadcrumb-wrapper d-flex align-items-center justify-content-between">
						<div>
							<h1>Product</h1>
							<p className="breadcrumbs"><span><a href="index">Home</a></span>
								<span><i className="mdi mdi-chevron-right"></i></span>Product
							</p>
						</div>
						<div>
							<a href="product-list" className="btn btn-primary"> Add Porduct</a>
						</div>
					</div>

					<div className="row">
						<div className="col-12">
							<div className="card card-default">
								<div className="card-header card-header-border-bottom d-flex justify-content-between">
									<div className="card-bar">
										<div className="col-lg-6 col-md-12">
											<input type="text" className="form-control" id="searchProduct"
												placeholder="search with product name.."/>
										</div>
										<div className="col-lg-6 col-md-12 sort">
											<div className="col-lg-4 col-md-6 p-space">
												<select className="form-control" id="dropdownCategory">
													<option value="ALL">ALL Category</option>
													<option value="MEN">Men's</option>
													<option value="WOMAN">Woman's</option>
													<option value="KID">Kids</option>
													<option value="OTHER">OTHER</option>
												</select>
											</div>
											<div className="col-lg-4 col-md-6 p-space">
												<select className="form-control" id="dropdownOrderBy">
													<option value="ALL">ORDER BY</option>
													<option value="MEN">Letest</option>
													<option value="WOMAN">Price Low - High</option>
													<option value="KID">Price High - Low</option>
													<option value="OTHER">OTHER</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p1.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Glomy shoes</h3>
														<p>$50</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p2.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Full Cap t-Shirt</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p3.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Full Sleeve t-Shirt</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p4.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Round Cap with Black Frame</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p5.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Mini Blue Bag</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p6.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Doctor's kit for baby</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p1.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Baby Smart shoes</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p2.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Full Sleeve T-Shirt</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p9.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Full Sleeve For Men's</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p10.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>shoes for Men</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p11.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Classic Purse for Woman</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p12.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>High Heal Scandle for Woman</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p13.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Big basket Lather Bag</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p14.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Lather shoes for Kids</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p15.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Baby Pink shoes</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p16.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Big Basket For Grossary</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p17.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Boat Ear buds</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p7.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Smart Shirt For Men</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p8.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>Smart Digital Watches</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6">
											<div className="card-wrapper">
												<div className="card-container">
													<div className="card-top">
														<img className="card-image" src="assets/img/products/p10.jpg"
															alt="" />
													</div>
													<div className="card-bottom">
														<h3>shoes for Men</h3>
														<p>$120</p>
													</div>
													<div className="card-action">
														<div className="card-edit"><i
																className="mdi mdi-circle-edit-outline"></i></div>
														<div className="card-preview"><i className="mdi mdi-eye-outline"></i>
														</div>
														<div className="card-remove"><i
																className="mdi mdi mdi-delete-outline"></i></div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<nav aria-label="Page navigation example p-0">
											<ul className="pagination pagination-seperated pagination-seperated-rounded">
												<li className="page-item">
													<a className="page-link" href="#" aria-label="Previous">
														<span aria-hidden="true"
															className="mdi mdi-chevron-left mr-1"></span> Prev
														<span className="sr-only">Previous</span>
													</a>
												</li>

												<li className="page-item active">
													<a className="page-link" href="#">1</a>
												</li>

												<li className="page-item">
													<a className="page-link" href="#">2</a>
												</li>

												<li className="page-item">
													<a className="page-link" href="#">3</a>
												</li>

												<li className="page-item">
													<a className="page-link" href="#" aria-label="Next">
														Next
														<span aria-hidden="true"
															className="mdi mdi-chevron-right ml-1"></span>
														<span className="sr-only">Next</span>
													</a>
												</li>
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <!-- Footer --> */}
			<footer className="footer mt-auto">
				<div className="copyright bg-white">
					<p>
						Copyright &copy; <span id="ec-year"></span><a className="text-primary"
							href="https://themeforest.net/user/ashishmaraviya" target="_blank"> Ekka Admin
							Dashboard</a>. All Rights Reserved.
					</p>
				</div>
			</footer>
			
		</div>
	</div>
    );
}
export default ProductGrid;