import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { Table, Input, Button, Select, Form, Modal} from "antd"; // Import Input and Button for filtering
import '@ant-design/cssinjs';
import '../style.scss';
import '../riot_admin.scss';
import SideBar from '../sidebar';
import Header from '../Header';

const { Search } = Input;
const { Option } = Select;
const CancelReasonModal = ({ visible, onCancel, onOk }) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then(values => {
      onOk(values.reason);
      form.resetFields();
    });
  };

  return (
    <Modal
      open={visible}
      title="Enter Cancellation Reason"
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="reason"
          label="Reason"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
const NewOrder = () => {
  const token = localStorage.getItem('token');
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]); // State for filtered data
  const [searchText, setSearchText] = useState('');
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const storeOrderIDInSession = (orderid) => {
    sessionStorage.setItem('selectedOrderID', orderid);
  };
  useEffect(() => {
    fetchOrders();
  }, [token]);
    const fetchOrders = async () => {
      try {
        const response = await axios.post("https://api.starmallonline.com/api/v1/order/getall", {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const data = response.data;
          setOrders(data);
          const orderIdsArray = data.map(order => order.orderid);
        localStorage.setItem('orderIds', JSON.stringify(orderIdsArray));
          setFilteredOrders(data); // Initialize filtered data with all orders
        } else {
          console.error("Failed to fetch orders");
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
  const handleSearch = (value) => {
    setSearchText(value);
    const filteredData = orders.filter(order => order.orderid.toString().toLowerCase().includes(value.toLowerCase()));
    setFilteredOrders(filteredData);
  };

  const handleViewClick = (orderID) => {
    const orderIdsArray = JSON.parse(localStorage.getItem('orderIds'));
  const selectedOrderIndex = orderIdsArray.indexOf(orderID);
  if (selectedOrderIndex !== -1) {
    localStorage.setItem('selectedID', orderID);
  } else {
    console.error("OrderID not found in orderIdsArray");
  }
  };
  const handleStatusChange = async (orderId, status) => {
    if (status === "Cancelled" ) {
      // If status is "Cancelled" or "CancelledByBuyer", open the modal
      setCancelModalVisible(true);
      setSelectedOrderId(orderId);
    } else {
      // If status is other than "Cancelled" or "CancelledByBuyer", proceed with status change
      await updateOrderStatus(orderId, status, null); // Pass null for reason
    }
  };

  const updateOrderStatus = async (orderId, status, reason) => {
    try {
      // Perform API request to update order status
      const response = await axios.post(
        "https://api.starmallonline.com/api/v1/order/editstatus",
        {
          orderid: orderId,
          orderStatus: status,
          admincancellreason: reason // Pass reason if provided
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        // Update the orders data or handle any other logic upon successful update
      } else {
        console.error("Failed to update order status");
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const handleCancelModalOk = (reason) => {
    // Close the modal and update order status with reason
    setCancelModalVisible(false);
    setSelectedOrderId(null); // Reset selected order ID
    updateOrderStatus(selectedOrderId, "Cancelled", reason);
  };

  const handleCancelModalCancel = () => {
    // Close the modal without updating order status
    setCancelModalVisible(false);
    setSelectedOrderId(null); // Reset selected order ID
  };

  // Define the columns for the DataTable
  const columns = [
    {
      title: "ID",
      dataIndex: "orderid",
      key: "orderid",
      // Add a filter input to the column
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder="Search ID"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) => record.orderid.toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
			setSearchText(searchText); 
        }
      },
      render: (text) => text,
    },
    {
      title: "Customer",
      dataIndex: ["shippingaddress", "name"],
      key: "shippingaddress.name",
    },
	{
		title: "Items",
		dataIndex: "products",
		key: "itemsCount",
		render: (products) => {
		  const totalProducts = products.reduce((total, product) => total + parseInt(product.productcount || 0, 10), 0);
		  return totalProducts;
		},
	  },	  
    {
      title: "Price",
      dataIndex: "products",
      key: "price",
      render: (products) =>
        products.reduce((acc, product) => acc + parseFloat(product.productprice || 0), 0).toFixed(2),
    },
    {
      title: "Payment",
      dataIndex: "paymentmethod",
      key: "paymentmethod",
    },
    {
		title: "Status",
		dataIndex: "orderStatus",
		key: "orderStatus",
		// render: (text, record) => (
		//   <Select defaultValue={text} style={{ width: 120 }} onChange={(value) => handleStatusChange(record.orderid, value)} >
		// 	<Option value="Placed">Placed</Option>
		// 	<Option value="OrderConfirmed">Order Confirmed</Option>
    //   <Option value="processingorder">Processing Order</Option>
    //   <Option value="productdispatched">Product Dispatched</Option>
		// 	<Option value="Cancelled"> Cancelled</Option>
    //   <Option value="CancelledByBuyer"> Cancelled By Buyer</Option>
		// 	<Option value="OutForDelivery"> Out For Delivery</Option>
		// 	<Option value="Delivered"> Delivered</Option>
		//   </Select>
		// ),
	  },
    {
      title: "Date",
      dataIndex: "createDate",
      key: "createDate",
      render: (text, record) => {
        const date = new Date(record.createDate);
        return date.toISOString().split('T')[0];
      },
    },
    
	{
		title: "Actions",
		key: "actions",
		render: (text, record) => (
		  <div className="btn-group mb-1">
			<Link to={`/order-detail`} onClick={() => handleViewClick(record.orderid)}>View</Link> {/* Include the orderid as a URL parameter */}
		  </div>
		),
	  },
	  
	  
  ];
const orderStatusChanged = async (selectedStatus) => {
  try {
    const response = await axios.post("https://api.starmallonline.com/api/v1/order/getall", {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 401 || response.status === 404) {
      Navigate("/AdminLogin");
      return;
    }
    if (response.status === 200) {
      const ordersData = response.data;
      if (selectedStatus === "Select Order Status") {
        setFilteredOrders(ordersData); // Reset to all orders when no status is selected
      } else {
        const filteredData = ordersData.filter(order => order.orderStatus === selectedStatus);
        setFilteredOrders(filteredData);
      }
    } else {
      console.error("Failed to fetch orders");
    }
  } catch (error) {
    console.error("Error fetching orders:", error);
  }
};
  return (
    <div className="wrapper">
      <SideBar />
      <div className="ec-page-wrapper">
      <Header />
      <div className="ec-content-wrapper">
        <div className="content" style={{ marginTop: "50px" }}>
          <div className="breadcrumb-wrapper breadcrumb-wrapper-2">
            <h1>Orders</h1>
          </div>
          <Search
            placeholder="Search by ID"
            onSearch={handleSearch}
            style={{ width: 200, marginBottom: 16 }}
          />
          <Select defaultValue={"Select Order Status"} onChange={(value) => orderStatusChanged(value)}>
			<Option value="Placed">Placed</Option>
			<Option value="OrderConfirmed">Order Confirmed</Option>
      <Option value="processingorder">Processing Order</Option>
      <Option value="productdispatched">Product Dispatched</Option>
      <Option value="Cancelled"> Cancelled</Option>
			<Option value="CancelledByBuyer"> Cancelled By Buyer</Option>
			<Option value="OutForDelivery"> Out For Delivery</Option>
			<Option value="Delivered"> Delivered</Option>
		  </Select>
          <Table dataSource={filteredOrders} columns={columns} />
        </div>
      </div>
    </div>
    <CancelReasonModal
        open={cancelModalVisible}
        onCancel={handleCancelModalCancel}
        onOk={handleCancelModalOk}
      />
    </div>
  );
}

export default NewOrder;
