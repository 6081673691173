import React, { useState, useEffect } from 'react';
import '../style.scss';
import '../riot_admin.scss';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'antd';
import axios from 'axios';
import { Select,DatePicker, Checkbox , Row, Col  } from 'antd';
import Stripe from "../../src/stripe/stripe";

const Repayment = () => {
  const token = localStorage.getItem('tokenforseller');
  const navigate = useNavigate();
  const [planname, setPan] = useState('');
  const [noofcategories, setAadhar] = useState('');
  const [noofproducts, setbusinesspan] = useState('');
  const [currentnoofproducts, setAccdetails] = useState('');
  const [termsandcondition, setAccname] = useState('');
  const [agreedprocesstopayment, setAccnumber] = useState('');
  const [payment, setifsccode] = useState('');
  const [paymentdetails, setBranch] = useState('');
  const [data, setData] = useState('');
  const [clientId, setClientId] = useState(null);
  const [planOptions, setPlanOptions] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState('');
  const [categoryList, setCategoriesList] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [editable, setEditable] = useState(true);
  const [clientSecret, setClientSecret] = useState(null);
  const [isSucceeded, setIsSucceeded] = useState(false);
  const [modalVisible ,setModalVisible] = useState(false);
  const [sellerPlanRank, setSellerPlanRank] = useState(null);
  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          'https://api.starmallonline.com/api/v1/login/getuserdetails',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
            },
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          setClientId(responseData.id); // Store the client ID in state

          // Now that you have the sellerid, you can make the API call to https://api.starmallonline.com/api/v1/seller/gebyid
          const geByIdResponse = await fetch(
            'https://api.starmallonline.com/api/v1/seller/getbyid',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json', // Set Content-Type header
                Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`, // Assuming you need to send the token
              },
              body: JSON.stringify({
                sellerid: responseData.id,
              }),
            }
          );

          if (geByIdResponse.ok) {
            const geByIdData = await geByIdResponse.json();
            console.log('Data from /seller/gebyid:', geByIdData);
            setSellerPlanRank(geByIdData.sellerplanrank);
            // Handle the response as needed
          } else {
            console.error('Error fetching seller details by id');
          }
        } else {
          console.error('Error fetching client details');
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      }
    };

    fetchClientDetails();
  }, [token]);
  
  useEffect(() => {
    const fetchPlanDetails = async () => {
      try {
        const response = await fetch(
          'https://api.starmallonline.com/api/v1/sellerplan/get',
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
  
        if (response.ok) {
          const responseData = await response.json();
          setPlanOptions(responseData); // Update this line
        } else {
          console.error('Error fetching client details');
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };
  
    fetchPlanDetails();
  }, []);
  useEffect(() => {
    const fetchCategoryNames = async () => {
      try {
        const response = await axios.put("https://api.starmallonline.com/api/v1/category/get",{},{
          headers: {
          'Content-Type': 'application/json',
          },
        });
        if (response.status === 401 || response.status === 404) {
          // Redirect to the login page for 401 or 404 errors
          // navigate('/login');
          return; // Exit early to prevent further logic execution
        }
        setCategoriesList(response.data);
      } catch (error) {
        console.error("Error fetching names:", error);
      }
    };

    fetchCategoryNames();
  
}, []);

const onFinish = async (e) => {
  console.log("Submit button clicked");
  e.preventDefault(); // Prevent the default form submission behavior

  // Check if termsandcondition and agreedprocesstopayment are true
  if (!termsandcondition || !agreedprocesstopayment) {
    console.error("Please agree to terms and conditions and payment process before submitting.");
    // You can also show an error message to the user
    return;
  }

  try {
    // Find the selected plan by sellerplanid from the stored planOptions
    const selectedPlan = planOptions.find(plan => plan.sellerplanid === selectedPlanId);

    // TODO: Replace with actual API call
    const response = await fetch("https://api.starmallonline.com/api/v1/seller/edit", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify({
        sellerid: clientId,
        planname: selectedPlan ? selectedPlan.planname : '', // Use the selected plan name
        noofcategories: selectedPlan ? selectedPlan.noofcategories : '',
        noofproducts: selectedPlan ? selectedPlan.noofproducts : '',
        freeshipping: selectedPlan ? selectedPlan.freeshipping : '',
        termsandcondition:'true',
        agreedprocesstopayment:'true',
        payment:'true',
        paymentdetails:'',
        sellerplanid: selectedPlanId,
        categoryid: selectedCategoryId,
      }),
    });

    if (response.status === 401 || response.status === 404) {
      // Redirect to the login page for 401 or 404 errors
      // navigate('/login');
      return; // Exit early to prevent further logic execution
    }

    if (response.ok) {
      const data = await response.json();
      console.log("POST request successful", data);
      if (data && data.length > 0) {
        setData(data);
      } else {
        setData([]); // If no data is received, set an empty array
      }
      // window.location.reload(); // Refresh the page
      // Optionally, you can update your component state or perform other actions here
    } else {
      console.error("POST request failed");
    }
  } catch (error) {
    console.error("Error posting data:", error);
  }
};



  const handleNameSelect = (sellerplanid) => {
		setSelectedPlanId(sellerplanid);
	  };
    const handleNameSelectforCategory = (categoryid) => {
      setSelectedCategoryId(categoryid);
      };

      const handlePayment = async () => {
        try {
          // Fetch seller plans
          const planResponse = await fetch("https://api.starmallonline.com/api/v1/sellerplan/get", {
            method: "PUT",
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          if (!planResponse.ok) {
            console.error('Error fetching seller plans');
            return;
          }
    
          const planData = await planResponse.json();
    
          // Find the selected plan by sellerplanid
          const selectedPlan = planData.find(plan => plan.sellerplanid === selectedPlanId);
    
          if (selectedPlan) {
            // Call handlePayment with the amount from the selected plan
            fetchPaymentData(selectedPlan.amount);
    
            // Disable the plan name dropdown after payment
            setEditable(false);
          }
        } catch (error) {
          console.error("Error fetching plans for payment:", error);
        }
      };
      const fetchPaymentData = async (amount, currency = 'INR', paymentMethod ='card') => {
        try {
          const response = await fetch( 
            "https://api.starmallonline.com/api/v1/payment/payment",
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("tokenforseller")}`,
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                "amount": parseFloat(amount),
                "currency": currency,
                "paymentmethod": paymentMethod
              })
            }
          );
    
          if (response.ok) {
            const data = await response.text();
            setClientSecret(data)
          } else {
            alert(JSON.stringify(response))
          }
        } catch (error) {
          console.error("Error in payment:", error);
        }
      };
      const paymenttake = () =>{
        const selectedPlan = planOptions.find(plan => plan.sellerplanid === selectedPlanId);
       const paytake = JSON.stringify({
          sellerid: clientId,
          planname: selectedPlan ? selectedPlan.planname : '', // Use the selected plan name
          noofcategories: selectedPlan ? selectedPlan.noofcategories : '',
          noofproducts: selectedPlan ? selectedPlan.noofproducts : '',
          freeshipping: selectedPlan ? selectedPlan.freeshipping : '',
          termsandcondition:'true',
          agreedprocesstopayment:'true',
          payment:'true',
          paymentdetails,
          sellerplanid: selectedPlanId,
          categoryid: selectedCategoryId,
          repayment:true
       });
       const paymentforSellerEdit = sessionStorage.setItem('paymentforSellerEdit',paytake)
       
      const fetchPaymentData = async (amount, currency = 'INR', paymentMethod ='card') => {
        try {
          const response = await fetch( 
            "https://api.starmallonline.com/api/v1/payment/payment",
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("tokenforseller")}`,
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                "amount": parseFloat(amount),
                "currency": currency,
                "paymentmethod": paymentMethod
              })
            }
          );
    
          if (response.ok) {
            const data = await response.text();
            setClientSecret(data)
            setModalVisible(true)
          } else {
            alert(JSON.stringify(response))
          }
        } catch (error) {
          console.error("Error in payment:", error);
        }
      };
      fetchPaymentData(selectedPlan.amount);
      }
      const handleLogout = () => {
        sessionStorage.clear();
      navigate('/AdminLogin');
      };
  return (
    <div className="wrapper">
      {/* PAGE WRAPPER */}
      <div className="ec-page-wrapper">
        {/* CONTENT WRAPPER */}
        <div className="ec-content-wrapper">
          <div className="content" style={{ marginTop: '50px' }}>
            <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
              <h1 style={{marginLeft:"45%"}}>Repayment</h1>
              <p className="breadcrumbs"><span><a href="index">Home</a></span>
								<span><i className="mdi mdi-chevron-right"></i></span><Link to="/AdminLogin">Login</Link>
                
                <span><i className="mdi mdi-chevron-right"></i></span> <button onClick={handleLogout}>Logout</button></p>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-4 col-lg-12">
                <div className="ec-cat-list card card-default mb-24px">
                  <div className="card-body">
                    <div className="ec-cat-form">
                      <form>
                         
                        <div className="form-group row">
                          <label htmlFor="text" className="col-12 col-form-label">
                          Plan Name
                          </label>
                          <div className="col-12">
  <Select
    style={{ width: '60%', border: '2px solid #8f8f8f' }}
    onChange={(value) => handleNameSelect(value)}
  >
    {planOptions
      .filter((name) => name.sellerplanrank >= sellerPlanRank) // Filter plans based on seller's plan rank
      .map((name) => (
        <Select.Option key={name.sellerplanid} value={name.sellerplanid}>
          {name.planname}
        </Select.Option>
      ))}
  </Select>
</div>

                        </div>

                        {/* <div className="form-group row">
                          <label htmlFor="slug" className="col-12 col-form-label">
                          Payment
                          </label>
                          <div className="col-12">
                            <input
                              id="slug"
                              name="slug"
                              className="form-control here set-slug"
                              type="text"
                              value={payment}
                              onChange={(e) => setifsccode(e.target.value)}
                              autoComplete="off"
                            />
                          </div>
                        </div> */}
                        <div className="row">
                        <Row gutter={16}>
         <Col span={24}>
            <Button type="primary" onClick={paymenttake}>Pay</Button>
         </Col>
      </Row>
                        </div>
                        {/* x */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       <Modal
        title="Payment Modal"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >{clientSecret && <Stripe style={{width:"50%",  alignSelf: 'center'}}clientSecret = {clientSecret} isSucceeded = {(value) => {
        console.log(value);
        setIsSucceeded(value);
        setClientSecret(null);

      }} />}
      </Modal>
    </div>
  );
};

export default Repayment;
