import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Modal, Table, Checkbox, Select, InputNumber } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const AssignDeliveryManEdit = ({ deliver, onClose }) => {
    const [form] = Form.useForm();
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [customers, setCustomers] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [bulkSelected, setBulkSelected] = useState(false);
    const [deliverPersons, setDeliverPersons] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState({ empid: '', empName: '' });
    const [product, setProduct] = useState([]);
    const [deliveryProducts, setDeliveryProducts] = useState([]);
    

    useEffect(() => {
        if (!token) {
            navigate('/AdminLogin');
            return;
        }

        const fetchData = async () => {
            try {
                const [customersResponse, deliverPersonsResponse, deliveryProductsResponse] = await Promise.all([
                    axios.get('https://api.starmallonline.com/api/customermilk/milkcustomer', {
                        headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' },
                    }),
                    axios.get('https://api.starmallonline.com/api/delivery/findall', {
                        headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' },
                    }),
                    axios.get('https://api.starmallonline.com/api/milkproducts/getAllProducts', {
                        headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' },
                    }),
                ]);
                setCustomers(customersResponse.data);
                setDeliverPersons(deliverPersonsResponse.data);
                const productsWithPid = deliveryProductsResponse.data.map(product => ({
                    pid: product.productid,
                    pname: product.pname,
                    qnty: product.qnty,
                }));
        
                setDeliveryProducts(productsWithPid);

                if (deliver) {
                    form.setFieldsValue(deliver);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                message.error('Failed to fetch data');
            }
        };

        fetchData();
    }, [token, deliver, form, navigate]);

    const handleEmployeeSelect = (value) => {
        const employee = deliverPersons.find(d => d.deliverymanid === parseInt(value));
        if (employee) {
            setSelectedEmployee({ empid: employee.deliverymanid, empName: employee.name });
            form.setFieldsValue({ employee: employee.name });
        }
    };

    const handleSelectCustomer = () => {
        setIsModalOpen(true);
    };
    const handleSelectSingleCustomer = (clientId) => {
        setSelectedRowKeys((prevKeys) => {
            if (prevKeys.includes(clientId)) {
                return prevKeys.filter((key) => key !== clientId);
            } else {
                return [...prevKeys, clientId];
            }
        });
    };
    const handleQuantityChange = (pid, value) => {
        const updatedProducts = deliveryProducts.map(item =>
            item.pid === pid ? { ...item, qnty: value } : item
        );
        setDeliveryProducts(updatedProducts);
    };
    const handleBulkSelect = (e) => {
        const checked = e.target.checked;
        setBulkSelected(checked);
        setSelectedRowKeys(checked ? customers.map(customer => customer.clientid) : []);
    };

    const handleSubmit = () => {
        const selectedCustomerNames = customers
            .filter(customer => selectedRowKeys.includes(customer.clientid))
            .map(customer => `${customer.firstname} - ${customer.milkQuantity}`)
            .join(', ');
        setSelectedCustomer(selectedCustomerNames);
        setIsModalOpen(false);
    };

    const handleDeliverUpdate = async (values) => {
        const clonedFormData = _.cloneDeep({
            ...values,
            adminid: localStorage.getItem('id'),
            assignid: deliver.assignid,
            empid: selectedEmployee.empid,
            empName: selectedEmployee.empName,
            clientid: selectedRowKeys,
            deliveryProducts: deliveryProducts.map(product => ({
                pid: product.pid,
                pname: product.pname,
                qnty: product.qnty,
            })),
        });
    
        console.log('Payload being sent:', clonedFormData); // Ensure payload structure
        try {
            const response = await axios.post(
                `https://api.starmallonline.com/api/assign-delivery/updateAssignDeliverPerson`,
                clonedFormData,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            if (response.status === 200) {
                onClose();
                message.success('Assign-Deliver-Man updated successfully');
            }
        } catch (error) {
            console.error('Error updating Deliver:', error);
            message.error('Error updating delivery');
        }
    };
    
    const customerColumns = [
        {
            title: 'Name',
            dataIndex: 'firstname',
            key: 'firstname',
        },
        {
            title: 'Item',
            dataIndex: 'milkitem',
            key: 'milkitem',
        },
        {
            title: 'Milk-Quantity',
            dataIndex: 'milkQuantity',
            key: 'milkQuantity',
        },
        {
            title: 'Delivery-Day',
            dataIndex: 'deliverDay',
            key: 'deliverDay',
        },
        {
            title: 'Select',
            key: 'select',
            render: (text, record) => (
                <Checkbox
                    checked={selectedRowKeys.includes(record.clientid)}
                    onChange={() => handleSelectSingleCustomer(record.clientid)}
                />
            ),
        },
    ];

    const productColumns = [
        {
            title: 'S.No',
            render: (_, __, index) => index + 1,
        },
        {
            title: 'Product Name',
            dataIndex: 'pname',
            key: 'pname',
        },
        {
            title: 'Quantity',
            render: (text, record) => (
                <InputNumber
                    min={1}
                    value={record.qnty}
                    onChange={(value) => handleQuantityChange(record.pid, value)}
                />
            ),
        },
    ];


    return (
        <Form
            form={form}
            name="Assign Deliver Man"
            initialValues={deliver}
            onFinish={handleDeliverUpdate}
            layout="vertical"
        >
            <Form.Item
                label="Select Employee"
                name="employee"
                rules={[{ required: true, message: 'Please select an employee' }]}>
                <Select
                    placeholder="Select Employee"
                    onChange={handleEmployeeSelect}
                    style={{ width: '100%' }}>
                    <Option value="">Select Employee</Option>
                    {deliverPersons.map(d => (
                        <Option key={d.deliverymanid} value={d.deliverymanid}>
                            {d.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label="Select Customer">
                <Input value={selectedCustomer} onClick={handleSelectCustomer} readOnly />
                <Modal
                    title={
                        <div>
                            <Checkbox checked={bulkSelected} onChange={handleBulkSelect}>
                                Select All
                            </Checkbox>
                        </div>
                    }
                    open={isModalOpen}
                    onCancel={() => setIsModalOpen(false)}
                    footer={[
                        <Button key="submit" type="primary" onClick={handleSubmit}>
                            Submit
                        </Button>,
                        <Button key="cancel" onClick={() => setIsModalOpen(false)}>
                            Cancel
                        </Button>,
                    ]}
                    width={800}>
                    <Table
                        dataSource={customers}
                        columns={customerColumns}
                        rowKey="clientid"
                        pagination={false}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: setSelectedRowKeys,
                        }}
                    />
                </Modal>
            </Form.Item>
            <Form.Item label="Delivery Products">
                <Table
                    dataSource={deliveryProducts}
                    columns={productColumns}
                    rowKey="productid"
                    pagination={false}
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
                <Button onClick={onClose} style={{ marginLeft: '10px' }}>
                    Cancel
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AssignDeliveryManEdit;
