import React, { useState, useEffect } from "react";
import "../../style.scss";
import "../../riot_admin.scss";
import SideBar from "../../vendor/sidebar/index";
import VendorHeader from "../../../src/vendor/header";
import { Link, useNavigate  } from "react-router-dom"; 
import { Input,Form, Upload, Button, Modal, Select,notification  } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from "axios";
import noImage from "./../../images/noimage.jpeg";
const VendorProfile = () => {
  const [clientId, setClientId] = useState(null);
  const [password, setNewPassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const token = sessionStorage.getItem('tokenforseller');
  const [description, setDescription] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [registration, setRegistration] = useState('');
  const [planexpiry, setPlanexpiry] = useState('');
  const [fileid, setUploadfiles] =useState([]); 
  const [fileList, setFileList] = useState([]); // To store uploaded files
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const toggleEditMode = () => {
    setEditMode(prevMode => !prevMode);
  };
  const navigate = useNavigate();
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          'https://api.starmallonline.com/api/v1/login/getuserdetails',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
            },
          }
        );

        if (response.ok) {
            const responseData = await response.json();
            setUserData(responseData);
            setClientId(responseData.id); // Store the client ID in state
            setFirstname(responseData.firstname);
  setLastname(responseData.lastname);
  setEmail(responseData.email);
  setMobile(responseData.mobile);
  setPlanexpiry(responseData.planexpiry);
  setRegistration(responseData.registration);

          } else {
            console.error('Error fetching client details');
          }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

  
  useEffect(() => {
    fetchClientDetails();
  }, []);
  
  const handleNewPasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    setPasswordsMatch(password === confirmpassword);
};

// Function to handle confirm password change and check if they match
const handleConfirmPasswordChange = (e) => {
    const confirmpassword = e.target.value;
    setConfirmpassword(confirmpassword);
    setPasswordsMatch(password === confirmpassword);
};
const onFinish = async (e) => {
  e.preventDefault(); // Prevent the default form submission behavior

  try {
    // TODO: Replace with actual API call
    const response = await fetch("https://api.starmallonline.com/api/v1/seller/passwordchange", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify({
      password,
        sellerid:clientId
      }),
    });

    if (response.status === 401 || response.status === 404) {
      // Redirect to the login page for 401 or 404 errors
      // navigate('/AdminLogin');
      return; // Exit early to prevent further logic execution
    }

    if (response.ok) {
      const data = await response.json();
      console.log("POST request successful");
      setDescription(data.description);
      if (data.status === "Success") {
        navigate('/AdminLogin');
      }
      // window.location.reload(); // Refresh the page
      // Optionally, you can update your component state or perform other actions here
    } else {
      console.error("POST request failed");
    }
  } catch (error) {
    console.error("Error posting data:", error);
  }
};
const formattedDate = new Date(planexpiry).toLocaleString('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false, // Use 24-hour format
});
const handleFileUpload = async (options) => {
  const { onSuccess, onError, file, onProgress } = options;
  const formData = new FormData();
  formData.append("file", file);
  
  try {
    const response = await axios.post("https://api.starmallonline.com/api/v1/fileupload/file",formData,{
      onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      onProgress({ percent: percentCompleted });
      },
      headers: {
      Authorization: 'Bearer ' + token, // Set the Authorization header with the token
      // 'Content-Type': 'application/json',
      'Content-Type': 'multipart/form-data',
      },
    }
    );
  
    if (response.status === 401 || response.status === 404 || !token) {
    // Redirect to the login page for 401 or 404 errors or if the token is not present
    navigate('/AdminLogin');
    return; // Exit early to prevent further logic execution
    }
    const responseData = response.data;
    setUploadfiles((prevUploadFiles) => [...prevUploadFiles, responseData.organiserId]);
    onSuccess("File uploaded");
    await axios.post("https://api.starmallonline.com/api/v1/seller/profilepic", {
      sellerid: clientId,
      profilepic: responseData.organiserId,
    }, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    });
   
  notification.success({
    message: response.data.description,
    duration: 3, // Duration in seconds
  });
    await fetchClientDetails();
    setEditMode(false);
  } catch (error) {
    onError("File upload failed");
  }
  };
  const handleEditProfile = async () => {
    try {
      const response = await axios.post(
        "https://api.starmallonline.com/api/v1/seller/edit",
        {
          // Pass the updated profile data to the API
          sellerid: clientId,
          firstname,
          lastname,
          email,
          mobile,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        console.log("Profile updated successfully");
        
  notification.success({
    message: response.data.description,
    duration: 3, // Duration in seconds
  });
        await fetchClientDetails();
        setEditMode(false);
      } else {
        console.error("Failed to update profile");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  }; 
  return(
        <div className="wrapper">
             <SideBar />
             <div className="ec-page-wrapper">
        <VendorHeader />
        <div className="ec-content-wrapper">
        <div className="content" >
        <div className="breadcrumb-wrapper d-flex align-items-center justify-content-between">
              <div>
                <h1>Seller Profile</h1>
              </div>
        <p className="breadcrumbs"><Link to="/Paymentreceipt" className="btn btn-primary">
                              Payment Receipt
                            </Link></p>
            </div>
            <div className="row">
            <div className="col-xl-6 col-lg-12">
							<div className="ec-cat-list card card-default mb-24px">
								<div className="card-body">
									<div className="ec-cat-form">
										<h4>Profile Details</h4>

                    <div className="form-group row">
  <label htmlFor="firstname" className="col-12 col-form-label">First Name</label> 
  <div className="col-12">
    <input id="firstname" name="firstname" className="form-control here slug-title" type="text" value={firstname} onChange={(e) => setFirstname(e.target.value)} autoComplete="off" readOnly={!editMode} />
  </div>
</div>
<div className="form-group row">
  <label htmlFor="lastname" className="col-12 col-form-label">Last Name</label> 
  <div className="col-12">
    <input id="lastname" name="lastname" className="form-control here slug-title" type="text" value={lastname} onChange={(e) => setLastname(e.target.value)} autoComplete="off" readOnly={!editMode} />
  </div>
</div>
<div className="form-group row">
  <label htmlFor="firstname" className="col-12 col-form-label">Email</label> 
  <div className="col-12">
    <input id="firstname" name="firstname" className="form-control here slug-title" type="text" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="off" readOnly={!editMode} />
  </div>
</div>
<div className="form-group row">
  <label htmlFor="firstname" className="col-12 col-form-label">Mobile</label> 
  <div className="col-12">
    <input id="firstname" name="firstname" className="form-control here slug-title" type="text" value={mobile} onChange={(e) => setMobile(e.target.value)} autoComplete="off" readOnly={!editMode} />
  </div>
</div>
<div className="form-group row">
  <label htmlFor="firstname" className="col-12 col-form-label">Registration Status</label> 
  <div className="col-12">
    <input id="firstname" name="firstname" className="form-control here slug-title" type="text" value={registration} onChange={(e) => setRegistration(e.target.value)} autoComplete="off" readOnly />
  </div>
</div>
<div className="form-group row">
  <label htmlFor="firstname" className="col-12 col-form-label">Planexpiry Date</label> 
  <div className="col-12">
    <input id="firstname" name="firstname" className="form-control here slug-title" type="text" value={formattedDate} onChange={(e) => setPlanexpiry(e.target.value)} autoComplete="off" readOnly />
  </div>
</div>
 <div className="col-md-12">
                    <img src={userData && userData.profilepic  != null ? userData.profilepic : noImage} alt="profile-photo" className="img-fluid" width={'200px'} height={'200px'}/>
                    <Form.Item
                name="uploadfile"
            >
               {editMode && (
                <Upload
                customRequest={handleFileUpload} 
                fileList={fileList}
                onChange={({ fileList }) => setFileList(fileList)}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
  </Upload>
               )}
            </Form.Item> 
												</div> 
                        <div className="row">
              <div className="col-12">
{editMode ? (
  <button name="submit" type="submit" className="btn btn-primary" onClick={handleEditProfile}>
    Save Changes
  </button>
) : (
  <button name="submit" type="submit" className="btn btn-primary" onClick={toggleEditMode}>
    {editMode ? "Cancel" : "Edit"}
  </button>
)}
              </div>
            </div>
                            
                            </div>
                            </div>
            </div>
            </div>
						<div className="col-xl-4 col-lg-12">
							<div className="ec-cat-list card card-default mb-24px">
								<div className="card-body">
									<div className="ec-cat-form">
										<h4>Change Password</h4>

										<form
                   onSubmit={onFinish}
										>
                      <div className="form-group row">
												<label for="text" className="col-12 col-form-label">New Password</label> 
												<div className="col-12">
													<input id="text" name="text" className="form-control here slug-title"  type="password"   onChange={handleNewPasswordChange} autoComplete="off" />
												</div>
											</div>
                      <div className="form-group row">
												<label for="text" className="col-12 col-form-label">Confirm Password</label> 
												<div className="col-12">
													<input id="text" name="text" className="form-control here slug-title"  type="password"  onChange={handleConfirmPasswordChange} autoComplete="off" />
												</div>
                        {!passwordsMatch && (
                    <div className="password-mismatch-error">Passwords do not match.</div>
                )}
											</div>
                      <div className="row">
												<div className="col-12">
													<button name="submit" type="submit" className="btn btn-primary">Update Password</button>
												</div>
											</div>
                      {description && <p>{description}</p>}
</form>

                            
                            </div>
                            </div>
            </div>
            </div>
            </div>

             </div>
            </div>
            </div>
            </div>
    );
}
export default VendorProfile;