import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Popconfirm, message, Row, Col, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import SideBar from '../sidebar.js';
import '../App.css';
import '../style.scss';
import "../riot_admin.scss";
import Header from '../Header.js';
import EditDeliveryMan from './EditDeliveryMan';

const ListDeliveryMan = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [deliver, setDeliver] = useState([]);
    const [selectedDeliver, setSelectedDeliver] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if (!token) {
            navigate('/AdminLogin');
            return;
        }
        fetchDelivers();
    }, []);

    const fetchDelivers = async () => {
        try {
            const response = await axios.get('https://api.starmallonline.com/api/delivery/findall', {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            });
            if (response.status === 401 || response.status === 404) {
                navigate('/AdminLogin');
                return;
            }
            setDeliver(response.data);
        } catch (error) {
            console.error('Error fetching employee:', error);
        }
    };
    const handleEdit = (deliver) => {
        setSelectedDeliver(deliver);
        setIsModalVisible(true);
    };
    const handleDelete = async (id) => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.delete(`https://api.starmallonline.com/api/delivery/deletedelivery`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
                params: {
                    did: id,
                },
            });

            if (response.status === 200) {
                message.success('Customer deleted successfully');
                fetchDelivers();
            } else {
                message.error('Failed to delete Customer');
            }
        } catch (error) {
            console.error('Error deleting Customer:', error);
            message.error('Failed to delete Customer');
        }
    };
    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedDeliver(null);
        fetchDelivers();
    };
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Delivery Code',
            dataIndex: 'deliverCode',
            key: 'deliverCode',
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Mobile No',
            dataIndex: 'mobileno',
            key: 'mobileno',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Button type="primary" onClick={() => handleEdit(record)} style={{ marginRight: '10px' }}>
                        Edit
                    </Button>
                    <Popconfirm
                        title="Are you sure you want to delete this group?"
                        onConfirm={() => handleDelete(record.deliverymanid)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="danger">Delete</Button>
                    </Popconfirm>
                </>
            ),
        },
    ];



    return (
        <div className='wrapper'>
            <SideBar />
            <div className='ec-page-wrapper'>
                <Header />
                <div className="ec-content-wrapper">
                    <div className='content'>
                        <div className='breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts'>
                            <div className='col-12'>
                                <h1>DELIVERYMANS LIST</h1>
                            </div>
                        </div>
                        <Table dataSource={deliver} columns={columns} rowKey='id' />
                    </div>
                </div>
            </div>
            {isModalVisible && (
                <Modal
                    title="Edit Deliverman"
                    open={isModalVisible}
                    onCancel={handleModalClose}
                    footer={null}
                >
                    <EditDeliveryMan deliver={selectedDeliver} onClose={handleModalClose} />
                </Modal>
            )}
        </div>
    );
};

export default ListDeliveryMan;
