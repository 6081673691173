import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import './style.scss';
import './riot_admin.scss';
import SideBar from './sidebar';
import { Input,Form, Upload, Button } from 'antd';
import Header from './Header';
import EditCategoryModal from './EditCategoryModal';
import { UploadOutlined } from '@ant-design/icons';

const menuItems = [
	{ text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
	// Add more menu items as needed
  ];


  const ClientDetails = () => {
	const token = localStorage.getItem('token');
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const handleToggleDropdown  = () => {
		setIsDropdownOpen(!isDropdownOpen);
	  };
	  const [categories, setCategories] = useState([]);
	  const [loading, setLoading] = useState(true);
	  const navigate = useNavigate();
	  const [fileList, setFileList] = useState([]); // To store uploaded files
	  const [fileid, setUploadfiles] =useState([]); 
	  const [categoryid, setCategoryid] =useState('');
	  const [categoryname, setCategoryname] =useState('');
	  const [slug, setSlug] =useState('');
	  const [sortdescription, setSortdescription] =useState('');
	  const [fulldescription, setFulldescription] =useState('');
	  const [producttag, setProducttag] =useState('');
	  const [data, setData] = useState([]);

	  const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
	  const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing
	
	  const handleFileUpload = async (options) => {
		const { onSuccess, onError, file, onProgress } = options;
		const formData = new FormData();
		formData.append("file", file);
	  
		try {
		  const response = await axios.post("https://api.starmallonline.com/api/v1/fileupload/file",formData,{
			  onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
				  (progressEvent.loaded * 100) / progressEvent.total
				);
				onProgress({ percent: percentCompleted });
			  },
			  headers: {
				Authorization: 'Bearer ' + token, // Set the Authorization header with the token
				// 'Content-Type': 'application/json',
				'Content-Type': 'multipart/form-data',
			  },
			}
		  );
	  
		  if (response.status === 401 || response.status === 404 || !token) {
			// Redirect to the login page for 401 or 404 errors or if the token is not present
			navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
	  
		  setUploadfiles((prevUploadFiles) => [...prevUploadFiles, response.data.organiserId]);
		  onSuccess("File uploaded");
		} catch (error) {
		  onError("File upload failed");
		}
	  };
	  

	  // Function to open the edit modal
	  const handleEditCategory = (category) => {
		setSelectedCategory(category);
		setShowEditModal(true);
	  };
	 // Function to handle the delete operation
	 // ...
	 const handleDeleteCategory = async (categoryId) => {
		try {
		  setLoading(true);
	  
		  // Make an API request to delete the category
		  const response = await axios.post(
			`https://api.starmallonline.com/api/v1/category/delete`,
			{
			  categoryid: categoryId, // Pass the categoryId as a payload
			},
			{
			  headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
				'Content-Type': 'application/json',
			  },
			}
		  );
		  if (response.status === 200) {
			// Refresh the page
			window.location.reload();
		  } else {
			console.error('Failed to delete category');
		  }
	  
		  // Rest of the code remains the same
		  // ...
		} catch (error) {
		  console.error('Error deleting category:', error);
		  setLoading(false);
		}
	  };
	  
  //
  
	
	  // Function to close the edit modal
	  const handleCloseEditModal = () => {
		setSelectedCategory(null);
		setShowEditModal(false);
	  };
	  const handleSaveEdit = (editedData) => {
		// Handle the save logic here and update the categories array with the edited data
		// You can use the editedData to make an API call to save the changes
		console.log('Edited data:', editedData);
		// After saving, you can update the categories array and close the modal
		// For example, update the categories array and close the modal as follows:
		// setCategories((prevCategories) => {
		//   const updatedCategories = prevCategories.map((category) => {
		//     if (category.id === selectedCategory.id) {
		//       return { ...category, ...editedData };
		//     }
		//     return category;
		//   });
		//   return updatedCategories;
		// });
		handleCloseEditModal();
	  };
	  useEffect(() => {
		if (!token) {
		  // Redirect to the login page if the token is not present
		  navigate('/AdminLogin');
		  return;
		}
		fetchData();
	  }, []); // Empty dependency array ensures the effect runs only once
	  const fetchData = async () => {
		try {
		  const response = await axios.post("https://api.starmallonline.com/api/v1/client/get",{},{
			
		  headers: {
			'Authorization': 'Bearer ' +token,
			'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
		  setData(response.data);
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };

	
	const [isSubMenuOpen, setIsSubMenuOpen] = useState({
		vendors: false,
		user: false,
		categories: false,
		products: false,
		orders: false,
		authentication: false,
		icons: false,
		otherpages: false,
	  });
	  const toggleSubMenu = (menuName) => {
		setIsSubMenuOpen((prevState) => ({
		  ...prevState,
		  [menuName]: !prevState[menuName],
		}));
	  };
	  
	return (
		<div className="wrapper">

		{/* <!-- LEFT MAIN SIDEBAR --> */}
		<SideBar/>

		<div className="ec-page-wrapper">

			{/* <!-- Header --> */}
			<Header/>

			{/* <!-- CONTENT WRAPPER --> */}
			<div className="ec-content-wrapper">
				<div className="content" >
					<div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
							<h1>Client Details</h1>
					</div>
					<div className="row">
						<div className="col-xl-8 col-lg-12">
							<div className="ec-cat-list card card-default">
								<div className="card-body">
									<div className="table-responsive">
                                    <table id="responsive-data-table" className="table">
											<thead>
												<tr>
													{/* <th>Thumb</th> */}
													<th>First Name</th>
													<th>Last Name</th>
													<th>Email</th>
													<th>Mobile Number</th>
													<th>Status</th>
                                                    <th>role</th>
													<th>Shipping Address</th>
													<th>Billing Address</th>
												</tr>
											</thead>
											<tbody>
                        {data.map((cdetails) => (
                          <tr key={cdetails.clientid}>
                            <td>{cdetails.firstname}</td>
                            <td>{cdetails.lastname}</td>
                            <td>{cdetails.email}</td>
							<td>{cdetails.mobile}</td>
                            <td>{cdetails.status}</td>
                            <td>{cdetails.role}</td>
							<td>
  {cdetails.shippingaddress &&
    cdetails.shippingaddress.map((address) => (
      <div key={address.clientaddressid}>
        <p> {address.name}</p>
        <p>{address.companyname}</p>
		<p>{address.address1}{address.address2}</p>
		<p>{address.city}{address.state}</p>
		<p>{address.phonenumber}{address.pincode}</p>
      </div>
    ))}
</td>
<td>
  {cdetails.billingaddress && (
    <div>
      <p>{cdetails.billingaddress.name}</p>
	  <p>{cdetails.billingaddress.address1},{cdetails.billingaddress.address2}</p>
	  <p>{cdetails.billingaddress.city},{cdetails.billingaddress.state},{cdetails.billingaddress.pincode}</p>
	  <p>{cdetails.billingaddress.phonenumber},{cdetails.billingaddress.email}</p>
      {/* Add other address fields as needed */}
    </div>
  )}
</td>
                          </tr>
                        ))}
                      </tbody>

										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> 


			{selectedCategory && (
  <EditCategoryModal
    category={selectedCategory}
    show={showEditModal} // Pass the value of showEditModal as isModalVisible
    onHide={handleCloseEditModal}
    onSave={handleSaveEdit}
  />
)}

</div>
	</div>
  );
}

export default ClientDetails;
